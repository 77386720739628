<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.42426 0.575736C1.18995 0.341421 0.810051 0.341421 0.575736 0.575736C0.341421 0.810051
       0.341421 1.18995 0.575736 1.42426L1.42426 0.575736ZM16.5757 17.4243C16.8101 17.6586 17.1899
       17.6586 17.4243 17.4243C17.6586 17.1899 17.6586 16.8101 17.4243 16.5757L16.5757
       17.4243ZM17.4243 1.42426C17.6586 1.18995 17.6586 0.810051 17.4243 0.575736C17.1899 0.341421
       16.8101 0.341421 16.5757 0.575736L17.4243 1.42426ZM0.575736 16.5757C0.341421 16.8101 0.341421
       17.1899 0.575736 17.4243C0.810051 17.6586 1.18995 17.6586 1.42426 17.4243L0.575736
       16.5757ZM0.575736 1.42426L8.57574 9.42426L9.42426 8.57574L1.42426 0.575736L0.575736
       1.42426ZM8.57574 9.42426L16.5757 17.4243L17.4243 16.5757L9.42426 8.57574L8.57574
       9.42426ZM9.42426 9.42426L17.4243 1.42426L16.5757 0.575736L8.57574 8.57574L9.42426
       9.42426ZM8.57574 8.57574L0.575736 16.5757L1.42426 17.4243L9.42426 9.42426L8.57574 8.57574Z"
      fill="#9D9D9D"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconClose',
};
</script>
