import Request from '@/core/services/request';

export default class AdminCreateRequest extends Request {
  constructor() {
    super('POST', 'admins', {
      first_name: {
        value: null,
      },
      last_name: {
        value: null,
      },
      patronymic_name: {
        value: null,
      },
      email: {
        value: null,
      },
      phone: {
        value: null,
      },
      role: {
        value: 'admin',
      },
    }, 'UserForm');
  }
}
