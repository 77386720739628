<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 10C11.2091 10 13 8.20914 13 6C13 3.79086 11.2091 2 9 2C6.79086 2 5 3.79086 5 6C5 8.20914
      6.79086 10 9 10Z"
      stroke="#373737"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.65728 13.6022C3.47301 14.3693 2.68359 15.5198 2.68359 16.6704H15.3152C15.3152 15.5198
      14.5257 14.3693 13.3415 13.6022C12.1573 12.8352 10.9731 12.4517 8.99938 12.4517C7.0257 12.4517
      5.84154 12.8352 4.65728 13.6022Z"
      stroke="#373737"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconUser',
};
</script>
