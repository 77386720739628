<template>
<div class="sponsor-list">
  <IPTable
    id="admins"
    :request="request"
    router-name="admins"
    creatable
    @create="handleModify"
    use-item-actions
    v-model:deleted="deleted"
    :rules="rules"
  >
    <template v-slot:actions="{ item }">
      <!-- Повторная отправка активации -->
      <IPButton v-if="item.status === 1" variant="nav" @click="handleResendActivation(item)">
        {{ t('user.buttons.resend-activation') }}
      </IPButton>
      <!-- Редактировать -->
      <IPButton variant="nav" @click="handleModify(item)">
        {{ t('common.buttons.update') }}
      </IPButton>
      <!-- Удалить -->
      <IPButton variant="nav" @click="handleDelete(item)">
        {{ t('common.buttons.delete') }}
      </IPButton>
    </template>
  </IPTable>

  <!-- Редактирование -->
  <AdminModify v-model="adminModalsList.modify" v-model:item="itemModify" @save="reload"/>
</div>
</template>

<script>
import {
  inject,
  reactive,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';

import IPTable from '@/core/ui/table/IPTable.vue';
import IPButton from '@/core/ui/buttons/IPButton.vue';

import AdminModify from '@/views/admin/AdminModify.vue';

import AdminListRequest from '@/requests/admin/AdminListRequest';
import AdminDeleteRequest from '@/requests/admin/AdminDeleteRequest';
import ResendActivationRequest from '@/requests/users/ResendActivationRequest';

export default {
  name: 'AdminList',

  components: {
    IPTable,
    IPButton,

    AdminModify,
  },

  setup() {
    const { t } = useI18n();
    const confirm = inject('confirm');
    const notification = inject('notification');

    const rules = {
      status: {
        typeFilter: 'select',
        options: [
          {
            label: t('common.labels.all'),
            value: null,
          },
          {
            label: t('admins.statuses.2'),
            value: 2,
          },
          {
            label: t('admins.statuses.1'),
            value: 1,
          },
        ],
      },
    };

    const request = ref(new AdminListRequest());

    const adminModalsList = reactive({
      modify: false,
    });

    /* Редактирование/создание */
    const itemModify = ref(null);
    function handleModify(item = null) {
      itemModify.value = item;
      adminModalsList.modify = true;
    }

    /* Удаление */
    const deleted = ref(false);
    async function handleDelete(item) {
      const isConfirmed = await confirm({
        title: t('user.confirms.deleted.title'),
        description: t('user.confirms.deleted.description', { name: item.email }),
        confirmBtn: t('common.buttons.delete'),
        rejectBtn: t('common.buttons.cancel'),
      });

      if (isConfirmed) {
        const request = new AdminDeleteRequest(item.id);
        const response = await request.sendRequest();

        if (response.code === 200) {
          deleted.value = true;
          notification(
            t('user.messages.deleted.title'),
            t('user.messages.deleted.description', { name: item.email }),
            'success',
          );
        }
      }
    }

    /* Повторная отправка активации */
    async function handleResendActivation(item) {
      const isConfirmed = await confirm({
        title: t('user.confirms.resend.title'),
        description: t('user.confirms.resend.description', { name: item.email }),
        confirmBtn: t('common.buttons.send'),
        rejectBtn: t('common.buttons.cancel'),
      });

      if (isConfirmed) {
        const request = new ResendActivationRequest(item.id);
        const response = await request.sendRequest();

        if (response.code === 200) {
          notification(
            t('user.messages.resend.title'),
            t('user.messages.resend.description', { name: item.email }),
            'success',
          );
        }
      }
    }

    function reload() {
      deleted.value = true;
    }

    return {
      t,
      rules,
      request,
      itemModify,
      adminModalsList,
      handleModify,
      handleDelete,
      handleResendActivation,
      deleted,
      reload,
    };
  },
};
</script>
