<template>
<div class="audit-filters">
  <IPSelect
    translate-option
    v-model="action"
    form
    :options="actions"
    label="audit.labels.actions"
    @select="filter"
  />

  <IPSelect
    v-model="user"
    form
    :options="users"
    label="audit.labels.users"
    @select="filter"
  />
</div>
</template>

<script>
import { ref } from 'vue';
import { map } from 'lodash';
import { getFio } from '@/core/services/user';
import { useI18n } from 'vue-i18n';

import IPSelect from '@/core/ui/form/IPSelect.vue';

import AuditActionsDict from '@/requests/audit/AuditActionsDict';
import AuditUsersDict from '@/requests/audit/AuditUsersDict';

export default {
  name: 'AuditFilters',

  emits: ['filterChange'],

  components: {
    IPSelect,
  },

  setup(props, { emit }) {
    const { t } = useI18n();

    const action = ref(null);
    const actions = ref([]);

    const user = ref(null);
    const users = ref([]);

    async function loadOptions() {
      const request = new AuditActionsDict();
      const response = await request.sendRequest();
      if (response.code === 200) {
        actions.value = response.data;
        actions.value.unshift({ label: 'common.labels.all', value: null });
      }
    }
    loadOptions();

    async function loadUsers() {
      const request = new AuditUsersDict();
      const response = await request.sendRequest();
      if (response.code === 200) {
        users.value = map(response.data, (item) => ({ label: getFio(item), value: item.id }));
        users.value.unshift({ label: t('common.labels.all'), value: null });
      }
    }
    loadUsers();

    function filter() {
      emit('filterChange', action.value, user.value);
    }

    return {
      action,
      actions,

      user,
      users,

      filter,
    };
  },
};
</script>

<style lang="scss">
.audit-filters {
  padding: 0 12px;
  display: flex;

  .ip-select {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
