export function getFio(user) {
  let label = `${user.last_name} ${user.first_name[0]}`;
  if (user.patronymic_name) {
    label += `.${user.patronymic_name[0]}.`;
  }

  return label;
}

export default {
  getFio,
};
