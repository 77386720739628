<template>
<div class="sponsor-list">
  <IPTable
    id="staff"
    :request="request"
    router-name="staff"
    creatable
    @create="handleModify"
    use-item-actions
    v-model:deleted="deleted"
    :rules="rules"
  >
    <template v-slot:actions="{ item }">
      <!-- Редактировать -->
      <IPButton variant="nav" @click="handleModify(item)">
        {{ t('common.buttons.update') }}
      </IPButton>

      <!-- Повторная отправка активации -->
      <IPButton v-if="item.status === 1" variant="nav" @click="handleResendActivation(item)">
        {{ t('user.buttons.resend-activation') }}
      </IPButton>

      <!-- Исследования -->
      <IPButton variant="nav" @click="handleStudies(item)">
        {{ t('sponsor.buttons.studies') }}
      </IPButton>

      <!-- Удалить -->
      <IPButton variant="nav" @click="handleDelete(item)">
        {{ t('common.buttons.delete') }}
      </IPButton>
    </template>
  </IPTable>

  <!-- Редактирование -->
  <StaffModify v-model="staffModalsList.modify" v-model:item="itemModify" @save="reload"/>

  <!-- Исследования -->
  <StaffStudies
    v-if="itemModify"
    v-model="staffModalsList.studies"
    v-model:item="itemModify"
  />
</div>
</template>

<script>
import {
  inject,
  provide,
  reactive,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';

import IPTable from '@/core/ui/table/IPTable.vue';
import IPButton from '@/core/ui/buttons/IPButton.vue';

import StaffModify from '@/views/staff/StaffModify.vue';
import StaffStudies from '@/views/staff/studies.vue';

import StaffListRequest from '@/requests/staff/StaffListRequest';
import StaffDeleteRequest from '@/requests/staff/StaffDeleteRequest';
import ResendActivationRequest from '@/requests/users/ResendActivationRequest';

export default {
  name: 'StaffList',

  components: {
    IPTable,
    IPButton,

    StaffModify,
    StaffStudies,
  },

  setup() {
    const { t } = useI18n();
    const confirm = inject('confirm');
    const notification = inject('notification');

    const rules = {
      status: {
        typeFilter: 'select',
        options: [
          {
            label: t('common.labels.all'),
            value: null,
          },
          {
            label: t('admins.statuses.2'),
            value: 2,
          },
          {
            label: t('admins.statuses.1'),
            value: 1,
          },
        ],
      },
    };

    const request = ref(new StaffListRequest());

    const staffModalsList = reactive({
      modify: false,
      studies: false,
    });
    provide('staffModalsList', staffModalsList);

    /* Редактирование/создание */
    const itemModify = ref(null);
    function handleModify(item = null) {
      itemModify.value = item;
      staffModalsList.modify = true;
    }

    /* Список исследований */
    function handleStudies(item) {
      itemModify.value = item;
      staffModalsList.studies = true;
    }

    /* Удаление */
    const deleted = ref(false);
    async function handleDelete(item) {
      const isConfirmed = await confirm({
        title: t('user.confirms.deleted.title'),
        description: t('user.confirms.deleted.description', { name: item.email }),
        confirmBtn: t('common.buttons.delete'),
        rejectBtn: t('common.buttons.cancel'),
      });

      if (isConfirmed) {
        const request = new StaffDeleteRequest(item.id);
        const response = await request.sendRequest();

        if (response.code === 200) {
          deleted.value = true;
          notification(
            t('user.messages.deleted.title'),
            t('user.messages.deleted.description', { name: item.email }),
            'success',
          );
        }
      }
    }

    /* Повторная отправка активации */
    async function handleResendActivation(item) {
      const isConfirmed = await confirm({
        title: t('user.confirms.resend.title'),
        description: t('user.confirms.resend.description', { name: item.email }),
        confirmBtn: t('common.buttons.send'),
        rejectBtn: t('common.buttons.cancel'),
      });

      if (isConfirmed) {
        const request = new ResendActivationRequest(item.id);
        const response = await request.sendRequest();

        if (response.code === 200) {
          notification(
            t('user.messages.resend.title'),
            t('user.messages.resend.description', { name: item.email }),
            'success',
          );
        }
      }
    }

    function reload() {
      deleted.value = true;
    }

    return {
      t,
      rules,
      request,
      itemModify,
      staffModalsList,
      handleModify,
      handleStudies,
      handleDelete,
      handleResendActivation,
      deleted,
      reload,
    };
  },
};
</script>
