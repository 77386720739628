<template>
  <div class="page page-forgot">
    <IPForm
      submit-text="common.buttons.next"
      class="forgot-form"
      @submit="submit"
      :request="request"
    >
      <IPInput
        id="forgot-form__email"
        v-model="request.attributes.email.value"
        :error="request.getError('email')"
        label="auth.labels.email"
        type="email"
        @blur="validator(request, 'email')"
      />
    </IPForm>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { validator } from '@/core/services/validator';

import IPForm from '@/core/ui/form/IPForm.vue';
import IPInput from '@/core/ui/form/IPInput.vue';

import ForgotRequest from '@/requests/auth/ForgotRequest';

export default {
  name: 'ForgotPage',

  components: {
    IPForm,
    IPInput,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();

    const request = ref(new ForgotRequest());
    async function submit() {
      const response = await request.value.sendRequest();

      if (response.code === 200) {
        store.commit('application/forgotEmail', request.value.attributes.email.value);
        await router.push({ name: 'forgot-sent' });
      }
    }

    return {
      t,
      request,
      submit,
      validator,
    };
  },
};
</script>
