<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.33203 4.5H15.6654"
      stroke="#1B85FF"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 7.71484V13.3398"
      stroke="#1B85FF"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 7.71484V13.3398"
      stroke="#1B85FF"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.28516 4.5L4.03344 14.9464C4.03344 15.4153 4.20545 15.865 4.51164 16.1965C4.81782
      16.528 5.23309 16.7143 5.6661 16.7143H12.3328C12.7658 16.7143 13.181 16.528 13.4872
      16.1965C13.7934 15.865 13.9654 15.4153 13.9654 14.9464L14.7137 4.5"
      stroke="#1B85FF"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 3.85659V1.92801C7 1.75752 7.08027 1.594 7.22316 1.47344C7.36604 1.35289 7.55984 1.28516
      7.7619 1.28516H10.2381C10.4402 1.28516 10.634 1.35289 10.7768 1.47344C10.9197 1.594 11 1.75752
      11 1.92801V3.85659"
      stroke="#1B85FF"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTrash',
};
</script>
