import {
  map,
  each,
  cloneDeep,
} from 'lodash';
import { call } from '@/core/services/http';
import Store from '@/store';

export default class Request {
  constructor(method, url, attributes = {}, name = null) {
    this.method = method;
    this.url = url;
    this.attributes = attributes;
    this.processing = false;
    this.isValid = true;
    this.name = name;

    this.initalAttributes = cloneDeep(this.attributes);

    map(this.attributes, (value, key) => { this.attributes[key].error = null; });
  }

  setAttribute(key, value) {
    if (this.attributes[key]) {
      this.attributes[key].value = value;
    }
  }

  getAttributes() {
    const data = {};
    each(this.attributes, (value, key) => { data[key] = value.value; });
    return data;
  }

  setAttributes(attributes = {}) {
    each(attributes, (value, key) => this.setAttribute(key, value));
  }

  clearAttributes() {
    this.attributes = cloneDeep(this.initalAttributes);
  }

  clearError(key) {
    if (this.attributes[key]) {
      this.attributes[key].error = null;
    }
  }

  setError(key, error) {
    if (this.attributes[key]) {
      this.attributes[key].error = error;
    }
  }

  setErrors(errors = {}) {
    each(errors, (error) => this.setError(error['field'], error['messages'][0]));
  }

  clearErrors() {
    map(this.attributes, (value, key) => { this.attributes[key].error = null; });
  }

  validateAll() {
    // TODO check value in API
    return this.isValid;
  }

  formIsValid() {
    // TODO check value in API
    return this.isValid;
  }

  getError(attribute) {
    if (this.attributes[attribute]) {
      return this.attributes[attribute].error;
    }
    return null;
  }

  isProcessing() {
    return this.processing;
  }

  setProcessing(value) {
    this.processing = value;
  }

  async sendRequest() {
    try {
      this.setProcessing(true);

      if (this.validateAll()) {
        const response = await call(this.method, this.url, this.getAttributes());

        if (response.code === 400) {
          this.setErrors(response.errors);
        }

        this.setProcessing(false);

        return response;
      }
    } catch (e) {
      Store.commit('errorHandler/message', e.message);
    } finally {
      this.setProcessing(false);
    }

    throw new Error('Неудачный запрос');
  }

  async sendFormData() {
    const formData = new FormData();

    Object.keys(this.attributes).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(this.attributes, key)) {
        formData.append(key, this.attributes[key].value);
      }
    });

    const response = await call(this.method, this.url, formData, 'multipart/form-data');

    return response;
  }

  getFormName() {
    return this.name;
  }
}
