import Request from '@/core/services/request';

export default class StudiesListRequest extends Request {
  constructor(id, userId) {
    super('POST', `sponsors/${id}/users/${userId}/studies`, {
      studies: {
        value: [],
      },
    });
  }
}
