<template>
  <IPModal
    v-model="showModalProfile.value"
    class="modal-profile"
    @close="request.clearAttributes()"
  >
    <template v-slot:title>{{ t('profile.modals.profile') }}</template>

    <IPForm
      submit-text="common.buttons.confirm"
      class="login-form"
      @submit="submit"
      :request="request"
    >
      <!-- Last name  -->
      <IPInput
        id="profile_form__last-name"
        v-model="request.attributes.last_name.value"
        :error="request.getError('last_name')"
        label="user.labels.last_name"
        type="text"
        @blur="validator(request, 'last_name')"
      />

      <!-- First name  -->
      <IPInput
        id="profile_form__first-name"
        v-model="request.attributes.first_name.value"
        :error="request.getError('first_name')"
        label="user.labels.first_name"
        type="text"
        @blur="validator(request, 'first_name')"
      />

      <!--Patronymic name -->
      <IPInput
        id="profile_form__last-name"
        v-model="request.attributes.patronymic_name.value"
        :error="request.getError('patronymic_name')"
        label="user.labels.patronymic_name"
        type="text"
        @blur="validator(request, 'patronymic_name')"
      />

      <!-- Phone  -->
      <IPInput
        id="profile_form__phone"
        v-model="request.attributes.phone.value"
        :error="request.getError('phone')"
        label="user.labels.phone"
        type="phone"
        @blur="validator(request, 'phone')"
      />

      <!-- Email  -->
      <IPInput
        id="profile_form__email"
        v-model="request.attributes.email.value"
        :error="request.getError('email')"
        label="user.labels.email"
        type="text"
        @blur="validator(request, 'email')"
      />

      <div class="ip-modal__title">{{ t('profile.modals.security') }}</div>
      <div class="ip-modal__description">{{ t('auth.labels.password') }}</div>

      <!-- Password  -->
      <IPInput
        id="profile_form__password"
        v-model="request.attributes.password.value"
        :error="request.getError('password')"
        label="user.labels.password"
        type="password"
      />

      <!-- Password repeat -->
      <IPInput
        id="profile_form__password_repeat"
        v-model="request.attributes.password_repeat.value"
        :error="request.getError('password_repeat')"
        label="user.labels.password_repeat"
        type="password"
      />
    </IPForm>
  </IPModal>
</template>

<script>
import { ref, inject, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { validator } from '@/core/services/validator';

import IPModal from '@/core/ui/IPModal.vue';
import IPForm from '@/core/ui/form/IPForm.vue';
import IPInput from '@/core/ui/form/IPInput.vue';
import ProfileEditRequest from '@/requests/profile/ProfileEditRequest';

export default {
  name: 'ProfileModal',

  components: {
    IPModal,
    IPForm,
    IPInput,
  },

  setup() {
    const store = useStore();
    const showModalProfile = inject('showModalProfile');
    const { t } = useI18n();

    const notification = inject('notification');

    const request = ref(new ProfileEditRequest());
    async function submit() {
      const response = await request.value.sendRequest();

      if (response.code === 200) {
        notification(
          t('profile.messages.save_successful.title'),
          t('profile.messages.save_successful.description'),
          'success',
        );
        store.commit('user/info', response.data);
        showModalProfile.value = false;
      }
    }

    watch(
      () => showModalProfile.value,
      (value) => {
        if (value) {
          const user = store.state.user.info;
          if (user) {
            request.value.setAttributes(user);
          }
        }
      },
    );

    return {
      t,
      request,
      validator,
      submit,
      showModalProfile,
    };
  },
};
</script>
