<template>
  <IPSelect
    class="language-switcher"
    v-model="locale"
    :options="languages"
    minimal
    :form="false"
    @select="loadItems"
  />
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import IPSelect from '@/core/ui/form/IPSelect.vue';

export default {
  name: 'LanguageSwitcher',

  components: {
    IPSelect,
  },

  setup() {
    const router = useRouter();
    const { locale } = useI18n();
    const languages = [
      {
        value: 'ru',
        label: 'RU',
      },
      {
        value: 'en',
        label: 'EN',
      },
    ];

    async function loadItems() {
      localStorage.setItem('application.locale', locale.value);
      router.go(0);
    }

    return {
      locale,
      languages,
      loadItems,
    };
  },
};
</script>
