<template>
  <div class="sponsor-list">
    <IPTable
      id="sponsor"
      :request="request"
      :rules="rules"
      router-name="sponsors"
      creatable
      @create="handleModify"
      use-item-actions
      v-model:deleted="deleted"
    >
      <template v-slot:actions="{ item }">
        <!-- Редактировать -->
        <IPButton variant="nav" @click="handleModify(item)">
          {{ t('common.buttons.update') }}
        </IPButton>

        <!-- Пользователи -->
        <IPButton variant="nav" @click="handleUsers(item)">
          {{ t('sponsor.buttons.users') }}
        </IPButton>

        <!-- Исследования -->
        <IPButton variant="nav" @click="handleStudies(item)">
          {{ t('sponsor.buttons.studies') }}
        </IPButton>

        <!-- Удалить -->
        <IPButton variant="nav" @click="handleDelete(item)">
          {{ t('common.buttons.delete') }}
        </IPButton>
      </template>
    </IPTable>

    <!-- Редактирование -->
    <SponsorModify v-model="sponsorModalsList.modify" v-model:item="itemModify" @save="reload" />

    <!-- Исследования -->
    <SponsorStudies
      v-if="itemModify"
      v-model="sponsorModalsList.studies"
      v-model:item="itemModify"
    />

    <!-- Пользователи -->
    <SponsorUsers v-model="sponsorModalsList.users" v-if="itemModify" v-model:item="itemModify" />
  </div>
</template>

<script>
import {
  ref,
  inject,
  provide,
  reactive,
} from 'vue';
import { useI18n } from 'vue-i18n';

import IPTable from '@/core/ui/table/IPTable.vue';
import IPButton from '@/core/ui/buttons/IPButton.vue';

import SponsorModify from '@/views/sponsors/modify.vue';
import SponsorStudies from '@/views/sponsors/studies.vue';
import SponsorUsers from '@/components/users/index.vue';

import SponsorsListRequest from '@/requests/sponsors/SponsorsListRequest';
import SponsorsDeleteRequest from '@/requests/sponsors/SponsorsDeleteRequest';

export default {
  name: 'SponsorsList',

  components: {
    IPTable,
    SponsorModify,
    SponsorUsers,
    SponsorStudies,
    IPButton,
  },

  setup() {
    const { t } = useI18n();
    const confirm = inject('confirm');
    const notification = inject('notification');

    const request = ref(new SponsorsListRequest());

    const rules = {
      country: {
        title: 'name_ru',
        sortable: false,
        searchable: false,
      },
    };

    const sponsorModalsList = reactive({
      modify: false,
      users: false,
      studies: false,
    });
    provide('sponsorModalsList', sponsorModalsList);

    /* Редактирование/создание */
    const itemModify = ref(null);
    function handleModify(item = null) {
      itemModify.value = item;
      sponsorModalsList.modify = true;
    }

    /* Список пользователей */
    function handleUsers(item) {
      itemModify.value = item;
      sponsorModalsList.users = true;
    }

    /* Список исследований */
    function handleStudies(item) {
      itemModify.value = item;
      sponsorModalsList.studies = true;
    }

    /* Удаление */
    const deleted = ref(false);
    async function handleDelete(item) {
      const isConfirmed = await confirm({
        title: t('sponsor.confirms.delete.title'),
        description: t('sponsor.confirms.delete.description', { name: item.name }),
        confirmBtn: t('common.buttons.delete'),
        rejectBtn: t('common.buttons.cancel'),
      });

      if (isConfirmed) {
        const request = new SponsorsDeleteRequest(item.id);
        const response = await request.sendRequest();

        if (response.code === 200) {
          deleted.value = true;
          notification(
            t('sponsor.messages.deleted.title'),
            t('sponsor.messages.deleted.description', { name: item.name }),
            'success',
          );
        }
      }
    }

    function reload() {
      deleted.value = true;
    }

    return {
      t,
      request,
      rules,
      itemModify,
      handleModify,
      handleUsers,
      handleStudies,
      sponsorModalsList,
      handleDelete,
      deleted,
      reload,
    };
  },
};
</script>
