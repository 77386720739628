<template>
  <IPModal @close="goBack" @keydown.esc="goBack">
    <template v-slot:title>
      <div class="modal-user-modify__title">
        <div @click="goBack" @keydown="goBack"><IconBack/></div>
        <div v-if="viewMode.item">{{ viewMode.item.first_name }}</div>
        <div v-else>{{ t('employee.modals.new_user') }}</div>
      </div>
    </template>

    <div v-if="!viewMode.item" class="model-user__new-description">
      {{ t('employee.messages.invitation') }}
    </div>

    <IPForm
      v-if="request"
      :submit-text="viewMode.item ? 'common.buttons.confirm' : 'common.buttons.create'"
      class="sponsor-modify-form"
      @submit="submit"
      :request="request"
    >
      <IPInput
        id="sponsor-modify-form__last_name"
        v-model="request.attributes.last_name.value"
        :error="request.getError('last_name')"
        label="user.labels.last_name"
        @blur="validator(request, 'last_name')"
      />

      <IPInput
        id="sponsor-modify-form__first_name"
        v-model="request.attributes.first_name.value"
        :error="request.getError('first_name')"
        label="user.labels.first_name"
        @blur="validator(request, 'first_name')"
      />

      <IPInput
        id="sponsor-modify-form__patronymic_name"
        v-model="request.attributes.patronymic_name.value"
        :error="request.getError('patronymic_name')"
        label="user.labels.patronymic_name"
        @blur="validator(request, 'patronymic_name')"
      />

      <IPInput
        id="sponsor-modify-form__email"
        v-model="request.attributes.email.value"
        :error="request.getError('email')"
        label="user.labels.email"
        @blur="validator(request, 'email')"
      />

      <IPInput
        id="sponsor-modify-form__phone"
        v-model="request.attributes.phone.value"
        :error="request.getError('phone')"
        label="user.labels.phone"
        @blur="validator(request, 'phone')"
      />
    </IPForm>
  </IPModal>
</template>

<script>
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { validator } from '@/core/services/validator';

import IPModal from '@/core/ui/IPModal.vue';
import IconBack from '@/core/ui/icons/IconBack.vue';
import IPForm from '@/core/ui/form/IPForm.vue';
import IPInput from '@/core/ui/form/IPInput.vue';

import SponsorUserCreateRequest from '@/requests/users/UserCreateRequest';
import SponsorUserUpdateRequest from '@/requests/users/UserUpdateRequest';

export default {
  name: 'UsersItem',

  components: {
    IPModal,
    IconBack,
    IPInput,
    IPForm,
  },

  props: {
    sponsorId: {
      type: Number,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const notification = inject('notification');
    const viewMode = inject('sponsorUsersModalView');

    function goBack() {
      viewMode.view = 'list';
      viewMode.item = null;
    }

    const request = ref(null);
    let scenario;
    if (viewMode.item) {
      request.value = new SponsorUserUpdateRequest(props.sponsorId, viewMode.item.id);
      request.value.setAttributes(viewMode.item);
      request.value.setAttribute('role', viewMode.role);
      scenario = 'updated';
    } else {
      request.value = new SponsorUserCreateRequest(props.sponsorId);
      request.value.setAttribute('role', viewMode.role);
      scenario = 'created';
    }

    async function submit() {
      const response = await request.value.sendRequest();

      if (response.code === 200) {
        notification(
          t(`user.messages.${scenario}.title`),
          t(`user.messages.${scenario}.description`, { name: request.value.attributes.email.value }),
          'success',
        );
        goBack();
      }
    }

    return {
      t,
      viewMode,
      request,
      submit,
      goBack,
      validator,
    };
  },
};
</script>

<style lang="scss">
.modal-user-modify__title {
  display: flex;
  justify-content: left;

  div:first-child {
    cursor: pointer;
    margin-right: 6px;
  }
}

.model-user__new-description {
  margin: 16px 0;
  color: #9D9D9D;

  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
</style>
