<template>
  <div class="audit">
    <AuditFilters @filterChange="changeFilters"/>

    <div class="audit__items">
      <AuditItem v-for="item in items" :key="item.id" :item="item" />
    </div>

    <IPLoader v-if="request.isProcessing()"/>

    <IPPagination
      :total="pagination.totalCount"
      v-model:page="page"
      @change-page="changePage"
    />
  </div>
</template>

<script>
import { ref } from 'vue';

import IPLoader from '@/core/ui/IPLoader.vue';
import AuditFilters from '@/views/audit/AuditFilters.vue';
import AuditItem from '@/views/audit/AuditItem.vue';
import IPPagination from '@/core/ui/IPPagination.vue';

import AuditListRequest from '@/requests/audit/AuditListRequest';

export default {
  name: 'AuditList',

  components: {
    IPLoader,
    AuditItem,
    IPPagination,
    AuditFilters,
  },

  setup() {
    const items = ref();
    const page = ref(1);
    const pagination = ref({
      totalCount: 0,
      count: 0,
    });

    const filters = ref({
      action_id: null,
      user_id: null,
      page: 1,
    });

    const request = ref(new AuditListRequest());
    async function loadItems() {
      request.value.setAttributes(filters.value);

      const response = await request.value.sendRequest();
      if (response.code === 200) {
        items.value = response.data.items;
        pagination.value = response.data.pagination;
      }
    }
    loadItems();

    function changePage(page) {
      filters.value.page = page;
      loadItems();
    }

    function changeFilters(action_id, user_id) {
      filters.value.action_id = action_id;
      filters.value.user_id = user_id;
      filters.value.page = 1;
      loadItems();
    }

    return {
      request,
      filters,
      page,
      pagination,
      items,

      loadItems,
      changePage,
      changeFilters,
    };
  },
};
</script>

<style lang="scss">
.audit {
  &__items {
    padding: 12px;
  }
}
</style>
