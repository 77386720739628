<template>
  <div class="page page_login">
    Письмо для восстановления пароля отправлено
    на почту - {{ forgotEmail }}
  </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
  name: 'MessagePage',

  beforeRouteEnter(to, from, next) {
    if (from.name === 'forgot') {
      next();
    } else {
      next({ name: 'forgot' });
    }
  },

  setup() {
    const store = useStore();

    const { forgotEmail } = store.state.application;

    return {
      forgotEmail,
    };
  },
};
</script>
