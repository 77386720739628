<template>
  <!-- Список пользователей -->
  <UsersList
    v-if="viewMode.view === 'list'"
    v-model="sponsorModalsList.users"
    :sponsor-id="item.id"
    @close="close"
  />

  <!-- Редактирование/Создание -->
  <UsersItem
    v-else-if="viewMode.view === 'item'"
    v-model="sponsorModalsList.users"
    :sponsor-id="item.id"
  />

  <!-- Список исследований -->
  <UsersStudies
    v-else-if="viewMode.view === 'studies'"
    v-model="sponsorModalsList.users"
    :sponsor-id="item.id"
  />
</template>

<script>
import { reactive, provide, inject } from 'vue';

import UsersList from '@/components/users/modals/UsersList.vue';
import UsersItem from '@/components/users/modals/UsersItem.vue';
import UsersStudies from '@/components/users/modals/UsersStudies.vue';

export default {
  name: 'SponsorUsers',

  components: {
    UsersList,
    UsersItem,
    UsersStudies,
  },

  props: {
    item: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const sponsorModalsList = inject('sponsorModalsList');

    const viewMode = reactive({ view: 'list', item: null, role: null });
    provide('sponsorUsersModalView', viewMode);

    function close() {
      emit('update:item', null);
      emit('update:modelValue', false);
    }

    return {
      viewMode,
      sponsorModalsList,
      close,
    };
  },
};
</script>
