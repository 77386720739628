<template>
  <form class="ip-form" @submit.prevent="handleSubmit" autocomplete="new-password">
    <fieldset :disabled="request.isProcessing()">
      <slot></slot>
    </fieldset>

    <fieldset class="ip-form__actions">
      <IPButton
        v-if="submitText"
        type="submit"
        variant="primary"
        adaptive
        :disabled="request.isProcessing()"
      >
        {{ t(submitText) }}
      </IPButton>
    </fieldset>
  </form>
</template>

<script>
import { useI18n } from 'vue-i18n';
import IPButton from '@/core/ui/buttons/IPButton.vue';
import Request from '@/core/services/request';

export default {
  name: 'IPForm',

  emits: ['submit'],

  components: {
    IPButton,
  },

  props: {
    request: {
      type: Request,
      required: true,
    },

    submitText: {
      type: String,
      default: 'Отправить',
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();

    function handleSubmit() {
      props.request.clearErrors();
      emit('submit');
    }

    return {
      t,
      handleSubmit,
    };
  },
};
</script>

<style lang="scss">
.ip-form {
  width: 100%;

  fieldset {
    outline: none;
    border: none;
  }

  .ip-input {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__actions {
    margin-top: 32px;
    display: flex;
  }
}
</style>
