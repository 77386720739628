<template>
  <div class="audit-item">
    <div class="audit-item__key"> {{ t(`audit.labels.change`) }} #{{ item.audit_key }}</div>
    <div class="audit-item__info">
      <div class="audit-item__info-item">
        <div class="audit-item__label">{{ t(`audit.labels.author`) }}</div>
        <div class="audit-item__value">{{ getFio(item.user) }}</div>
      </div>
      <div class="audit-item__info-item">
        <div class="audit-item__label">{{ t(`audit.labels.action`) }}</div>
        <div class="audit-item__value">{{ t(`audit.actions.${item.audit_action_id}`) }}</div>
      </div>
      <div class="audit-item__info-item">
        <div class="audit-item__label">{{ t(`audit.labels.date`) }}</div>
        <div class="audit-item__value">
          {{ moment.utc(item.created_at).tz('Europe/Moscow').format('D.MM.Y HH:mm') }}
        </div>
      </div>
      <div class="audit-item__info-item">
        <div class="audit-item__label">{{ t(`audit.labels.module`) }}</div>
        <div class="audit-item__value">{{ item.module }}</div>
      </div>
    </div>

    <IPButton adaptive size="sm" variant="primary-outline" @click="handleToggle">
      <span v-if="!isOpened">{{ t(`audit.buttons.more`) }}</span>
      <span v-else>{{ t(`audit.buttons.hidden_more`) }}</span>
    </IPButton>

    <div class="audit-item__actions" v-if="isOpened">
      <div
        class="audit-item__action"
        v-for="action in item.actions"
        :key="action.model_id"
      >
        <div class="audit-item__action-name">
          {{ t(`${action.model.toLowerCase()}.name`) }} # {{ action.model_id }}
        </div>
        <div
          class="audit-item__action-attributes"
          v-for="key in getKeys(action)"
          :key="key"
        >
          <div class="audit-item__action-attribute">
            <div class="audit-item__action-attribute-column">
              {{ t(`${action.model.toLowerCase()}.table.${key}`) }}
            </div>
            <div
              class="audit-item__action-attribute-column audit-item__action-attribute-old"
              :class="{
                'audit-item__action-attribute-old_changed':
                action.old_attributes[key] !== action.new_attributes[key]
              }"
            >
              {{ action.old_attributes[key] ?? '—' }}
            </div>
            <div
              class="audit-item__action-attribute-column audit-item__action-attribute-new"
              :class="{
                'audit-item__action-attribute-new_changed':
                action.old_attributes[key] !== action.new_attributes[key]
              }"
            >
              {{ action.new_attributes[key] ?? '—' }}
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { getFio } from '@/core/services/user';
import { useI18n } from 'vue-i18n';
import moment from 'moment-timezone';

import IPButton from '@/core/ui/buttons/IPButton.vue';

export default {
  name: 'AuditItem',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  components: {
    IPButton,
  },

  setup() {
    const { t } = useI18n();
    const isOpened = ref(false);

    function handleToggle() {
      isOpened.value = !isOpened.value;
    }

    function getKeys(action) {
      let keys = Object.keys(action.new_attributes);
      if (keys.length === 0) {
        keys = Object.keys(action.old_attributes);
      }
      return keys;
    }

    return {
      t,
      isOpened,
      handleToggle,
      getFio,
      getKeys,
      moment,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/shadows";
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.audit-item {
  @include shadow_light;
  margin-bottom: 15px;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 20px;

  &__key {
    @include font_details;
    color: $color_light_grey;
    margin-bottom: 20px;
  }

  &__info {
    display: flex;
    margin-bottom: 16px;
  }

  &__info-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }

  &__label {
    @include font_subtitle;
    margin-right: 5px;

    &:after {
      content: ':';
    }
  }

  &__actions {
    margin-top: 20px;
  }

  &__action {
    padding: 15px;
    background: $color_super_light_grey;
  }

  &__action-name {
    @include font_subtitle;
    margin-bottom: 16px;
  }

  &__action-attribute {
    @include font_details;
    display: flex;
    background: $color_light_grey;
  }

  &__action-attribute-column {
    flex: 1;
    border-right: 1px solid $color_grey_outline;
    padding: 5px;
  }

  &__action-attribute-new_changed {
    background: #aff5b4;

    &:before {
      content: '+';
      color: #FFFFFF;
      padding-right: 15px;
    }
  }

  &__action-attribute-old_changed {
    background: #f85149;

    &:before {
      content: '-';
      color: #FFFFFF;
      padding-right: 15px;
    }
  }
}
</style>
