import Request from '@/core/services/request';

export default class StudiesListRequest extends Request {
  constructor(id) {
    super('POST', `sponsors/${id}/studies`, {
      studies: {
        value: [],
      },
    });
  }
}
