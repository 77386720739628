export default {
  namespaced: true,

  state: {
    forgotEmail: null,
  },

  mutations: {
    forgotEmail(state, value) {
      state.forgotEmail = value;
    },
  },
};
