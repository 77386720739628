import Request from '@/core/services/request';

export default class StudiesListRequest extends Request {
  constructor() {
    super('GET', 'studies', {
      id: {
        value: null,
      },
      page: {
        value: 1,
      },
      sort_direction: {
        value: null,
      },
      sort_field: {
        value: null,
      },
      name: {
        value: null,
      },
      sponsor: {
        value: null,
      },
      expected_total_enrollment_min: {
        value: null,
      },
      expected_total_enrollment_max: {
        value: null,
      },
      actual_sponsor: {
        value: null,
      },
      is_active: {
        value: null,
      },
    });
  }
}
