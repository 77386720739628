<template>
<div class="ip-empty-data">
  <slot/>
</div>
</template>

<script>
export default {
  name: 'IPEmptyData',
};
</script>

<style lang="scss">
.ip-empty-data {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #9D9D9D;
}
</style>
