import ValidateRequest from '@/requests/validator/ValidateRequest';

export async function validator(request, key) {
  request.clearError(key);

  const requestValidate = new ValidateRequest();
  requestValidate.setAttribute('form', request.getFormName());
  requestValidate.setAttribute('field', key);
  requestValidate.setAttribute('value', request.attributes[key].value);

  const response = await requestValidate.sendRequest();
  if (response.code === 400) {
    request.setError(key, response.errors[0].messages[0]);
  }
}

export default {
  validator,
};
