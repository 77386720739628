<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3569 9C17.3569 9 14.8497 14.1429 8.99972 14.1429C3.14972 14.1429 0.642578 9 0.642578
      9C0.642578 9 3.14972 3.85715 8.99972 3.85715C14.8497 3.85715 17.3569 9 17.3569 9Z"
      stroke="#9D9D9D"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.00014 11.5714C10.4203 11.5714 11.5716 10.4201 11.5716 8.99999C11.5716 7.57983 10.4203
      6.42856 9.00014 6.42856C7.57998 6.42856 6.42871 7.57983 6.42871 8.99999C6.42871 10.4201
      7.57998 11.5714 9.00014 11.5714Z"
      stroke="#9D9D9D"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      v-if="!visible"
      d="M1.5 16.0714L16.0714 1.5"
      stroke="#9D9D9D"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconEye',

  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
