import Request from '@/core/services/request';

export default class StaffListRequest extends Request {
  constructor() {
    super('GET', 'staff', {
      id: {
        value: null,
      },
      page: {
        value: 1,
      },
      sort_direction: {
        value: null,
      },
      sort_field: {
        value: null,
      },
      first_name: {
        value: null,
      },
      last_name: {
        value: null,
      },
      patronymic_name: {
        value: null,
      },
      phone: {
        value: null,
      },
      email: {
        value: null,
      },
      status: {
        value: null,
      },
    });
  }
}
