import Request from '@/core/services/request';

export default class AuditListRequest extends Request {
  constructor() {
    super('GET', 'audit/grouped', {
      action_id: {
        value: null,
      },
      user_id: {
        value: null,
      },
      page: {
        value: 1,
      },
    });
  }
}
