<template>
  <div class="sponsor-list">
    <IPTable
      id="user"
      :request="request"
      :rules="rules"
      router-name="users"
      use-item-actions
      v-model:deleted="deleted"
    >
      <template v-slot:actions="{ item }">
        <!-- Повторная отправка активации -->
        <IPButton v-if="item.status === 1" variant="nav" @click="handleResendActivation(item)">
          {{ t('user.buttons.resend-activation') }}
        </IPButton>

        <!-- Удалить -->
        <IPButton variant="nav" @click="handleDelete(item)">
          {{ t('common.buttons.delete') }}
        </IPButton>
      </template>
    </IPTable>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';

import IPTable from '@/core/ui/table/IPTable.vue';
import IPButton from '@/core/ui/buttons/IPButton.vue';

import AllUsersListRequest from '@/requests/users/AllUsersListRequest';
import UsersDeleteRequest from '@/requests/users/UsersDeleteRequest';
import ResendActivationRequest from '@/requests/users/ResendActivationRequest';

export default {
  name: 'UsersList',

  components: {
    IPTable,
    IPButton,
  },

  setup() {
    const { t } = useI18n();
    const confirm = inject('confirm');
    const notification = inject('notification');

    const request = ref(new AllUsersListRequest());

    const rules = {
      role: {
        title: 'name',
        sortable: false,
        searchable: false,
      },
      sponsor: {
        title: 'name',
        sortable: false,
        searchable: false,
      },
      status: {
        typeFilter: 'select',
        options: [
          {
            label: t('common.labels.all'),
            value: null,
          },
          {
            label: t('admins.statuses.2'),
            value: 2,
          },
          {
            label: t('admins.statuses.1'),
            value: 1,
          },
        ],
      },
    };

    /* Повторная отправка активации */
    async function handleResendActivation(item) {
      const isConfirmed = await confirm({
        title: t('user.confirms.resend.title'),
        description: t('user.confirms.resend.description', { name: item.email }),
        confirmBtn: t('common.buttons.send'),
        rejectBtn: t('common.buttons.cancel'),
      });

      if (isConfirmed) {
        const request = new ResendActivationRequest(item.id);
        const response = await request.sendRequest();

        if (response.code === 200) {
          notification(
            t('user.messages.resend.title'),
            t('user.messages.resend.description', { name: item.email }),
            'success',
          );
        }
      }
    }

    /* Удаление */
    const deleted = ref(false);
    async function handleDelete(item) {
      const isConfirmed = await confirm({
        title: t('user.confirms.deleted.title'),
        description: t('user.confirms.deleted.description', { name: item.email }),
        confirmBtn: t('common.buttons.delete'),
        rejectBtn: t('common.buttons.cancel'),
      });

      if (isConfirmed) {
        const request = new UsersDeleteRequest(item.sponsor.id, item.id);
        const response = await request.sendRequest();

        if (response.code === 200) {
          deleted.value = true;
          notification(
            t('user.messages.deleted.title'),
            t('user.messages.deleted.description', { name: item.email }),
            'success',
          );
        }
      }
    }

    return {
      t,
      request,
      rules,
      handleResendActivation,
      handleDelete,
      deleted,
    };
  },
};
</script>
