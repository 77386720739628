<template>
  <div class="layout-main">
    <SiteHeader/>
    <div class="layout-main__content">
      <h1 class="layout-main__title" v-if="title">{{ t(title) }}</h1>

      <router-view/>
    </div>
    <div class="layout-main__footer"></div>
  </div>

  <ProfileModal />
</template>

<script>
import { reactive, provide, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import SiteHeader from '@/components/site/SiteHeader.vue';
import ProfileModal from '@/components/profile/ProfileModal.vue';

export default {
  name: 'MainLayout',

  components: {
    ProfileModal,
    SiteHeader,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const title = computed(() => route.meta.title);

    const isShown = reactive({ value: false });
    provide('showModalProfile', isShown);

    return {
      title,
      t,
    };
  },
};
</script>

<style lang="scss">
.layout-main {
  &__content {
    margin: 0 100px;
  }

  &__title {
    margin-bottom: 25px;
  }

  &__footer {
    margin-top: 48px;
  }
}
</style>
