<template>
  <div class="page page-recovery">
    <div v-if="message" class="page_login__message">
      {{ message }}
    </div>

    <IPForm
      submit-text="common.buttons.next"
      class="recovery-form"
      @submit="submit"
      :request="request"
      v-if="codeIsValid"
    >
      <IPInput
        id="login_form__password"
        v-model="request.attributes.password.value"
        :error="request.getError('password')"
        label="auth.labels.password"
        type="password"
      />
      <IPInput
        id="login_form__password_repeat"
        v-model="request.attributes.password_repeat.value"
        :error="request.getError('password_repeat')"
        label="auth.labels.password_repeat"
        subtitle="auth.input_subtitles.password"
        type="password"
      />
    </IPForm>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import IPForm from '@/core/ui/form/IPForm.vue';
import IPInput from '@/core/ui/form/IPInput.vue';

import RecoveryRequest from '@/requests/auth/RecoveryRequest';
import ActivateCheckCodeRequest from '@/requests/auth/ActivateCheckCodeRequest';

export default {
  name: 'RecoveryPage',

  components: {
    IPForm,
    IPInput,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const message = ref('Загрузка');
    const codeIsValid = ref(false);

    async function checkCode() {
      if (!route.query.code) {
        message.value = 'отсутвует обязательный параметр code';
      } else {
        const checkRequest = new ActivateCheckCodeRequest(route.query.code);
        const response = await checkRequest.sendRequest();

        if (response.code === 200) {
          codeIsValid.value = true;
          message.value = 'Придумайте пароль';
        }
      }
    }
    checkCode();

    const request = ref(new RecoveryRequest());
    async function submit() {
      request.value.setAttribute('code', route.query.code);
      const response = await request.value.sendRequest();

      if (response.code === 200) {
        await router.push({ name: 'login' });
      }
    }

    return {
      message,
      codeIsValid,
      request,
      submit,
    };
  },
};
</script>

<style lang="sass">

</style>
