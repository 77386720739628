<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3465_4777)">
      <path
        d="M12 17L4.07071 9.07071C4.03165 9.03165 4.03165 8.96834 4.07071 8.92929L12 0.999996"
        stroke="#1B85FF"
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3465_4777">
        <rect width="18" height="18" fill="white" transform="translate(18) rotate(90)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: 'IconBack',
};
</script>
