import Axios from 'axios';
import config from '@/config';
import Store from '@/store';

export async function call(method, url, payload = {}, contentType = 'application/json') {
  const headers = {
    'Content-Type': contentType,
    'X-Language': localStorage.getItem('application.locale') || 'ru',
  };

  const { token } = Store.state.user;
  if (token) {
    headers['X-Auth-Token'] = token;
  }

  const request = {
    url,
    method,
    headers,
    baseURL: config.urls.api,
  };

  if (['GET', 'DELETE'].includes(method)) {
    request.params = payload;
  } else {
    request.data = payload;
  }

  const response = await Axios(request);

  if (response.data) {
    return response.data;
  }

  return null;
}

export default {
  call,
};
