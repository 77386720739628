import Request from '@/core/services/request';

export default class UserCreateRequest extends Request {
  constructor(id) {
    super('POST', `sponsors/${id}/users`, {
      first_name: {
        value: null,
      },
      last_name: {
        value: null,
      },
      patronymic_name: {
        value: null,
      },
      email: {
        value: null,
      },
      phone: {
        value: null,
      },
      role: {
        value: null,
      },
    }, 'UserForm');
  }
}
