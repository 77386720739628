import Request from '@/core/services/request';

export default class SponsorsListRequest extends Request {
  constructor() {
    super('GET', 'sponsors', {
      id: {
        value: null,
      },
      page: {
        value: 1,
      },
      sort_direction: {
        value: null,
      },
      sort_field: {
        value: null,
      },
      name: {
        value: null,
      },
      actual_address: {
        value: null,
      },
      legal_address: {
        value: null,
      },
      tin: {
        value: null,
      },
    });
  }
}
