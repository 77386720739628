import { createApp } from 'vue';
import { loadLocaleMessages, setupI18n, setI18nLanguage } from '@/core/services/i18n';
import App from '@/App.vue';
import vClickOutside from 'click-outside-vue3';
import Confirm from '@/core/plugins/confirm';
import Notification from '@/core/plugins/notification/index';
import router from '@/router';
import store from '@/store';

const i18n = setupI18n();

const locale = localStorage.getItem('application.locale') || 'ru';
(async () => {
  await loadLocaleMessages(i18n, locale);
})();
setI18nLanguage(i18n, locale);

createApp(App)
  .use(vClickOutside)
  .use(Confirm)
  .use(Notification)
  .use(store)
  .use(i18n)
  .use(router)
  .mount('#app');
