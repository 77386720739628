import { nextTick, ref } from 'vue';
import { createI18n } from 'vue-i18n';

import LanguageViewRequest from '@/requests/languages/LanguageViewRequest';

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
}

export function setupI18n(options = {
  legacy: true,
  mode: 'composition',
  fallbackLocale: 'en',
}) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

export async function loadLocaleMessages(i18n, locale) {
  const request = ref(new LanguageViewRequest());
  request.value.setAttribute('language', locale);
  const response = await request.value.sendRequest();

  if (response.code === 200) {
    i18n.global.setLocaleMessage(locale, response.data[locale]);
  }

  return nextTick();
}
