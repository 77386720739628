<template>
<div :class="className" @click="changeSort" @keydown="changeSort">
  <slot/>

  <div class="ip-table-cell__sort" v-if="sortable && sortField === name">
    <IconDropdown :direction="sortDirection === 'asc' ? 'up' : 'down'"/>
  </div>
</div>
</template>

<script>
import { computed } from 'vue';

import IconDropdown from '@/core/ui/icons/IconDropdown.vue';

export default {
  name: 'IPTableCell',

  components: {
    IconDropdown,
  },

  props: {
    name: {
      type: String,
    },

    sortable: {
      type: Boolean,
      default: false,
    },

    sortDirection: {
      type: String,
      validator(value) {
        return [
          null,
          'asc',
          'desc',
        ].includes(value);
      },
    },

    sortField: {
      type: String,
    },

    align: {
      type: String,
      default: 'left',
    },
  },

  setup(props, { emit }) {
    const className = computed(() => {
      const styles = ['ip-table-cell', `ip-table-cell_align-${props.align}`];

      if (props.sortable) {
        styles.push('ip-table-cell_sortable');
      }

      if (props.name) {
        styles.push(`ip-table-cell_${props.name}`);
      }

      return styles;
    });

    function changeSort() {
      if (props.sortable) {
        emit('update:sortDirection', props.sortDirection === 'asc' ? 'desc' : 'asc');
        emit('update:sortField', props.name);
      }
    }

    return {
      className,
      changeSort,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/colors";

.ip-table-cell {
  display: flex;
  flex: 1 0;
  padding: 15px;
  overflow-wrap: anywhere;

  &_sortable {
    cursor: pointer;
  }

  &__sort {
    margin-left: 6px;
  }

  &_id {
    flex: 0 0 50px;
  }

  &_tin {
    flex: 0 0 140px;
  }

  &_action {
    flex: 0 0 20px;
  }

  &_align-left {
    justify-content: left;
  }

  &_align-center {
    justify-content: center;
  }

  &_align-right {
    justify-content: right;
  }
}
</style>
