import Request from '@/core/services/request';

export default class LanguageViewRequest extends Request {
  constructor() {
    super('GET', 'language', {
      language: {
        value: null,
      },
    });
  }
}
