<template>
  <div class="site-header">
    <div class="site-header__brand">{{ t('common.brand') }}</div>

    <div class="site-header__nav">
      <!-- Спонсоры -->
      <IPButton to="sponsors" variant="nav">{{ t('common.menu.sponsors') }}</IPButton>

      <!-- Сотрудники -->
      <IPButton to="staff" variant="nav">{{ t('common.menu.staff') }}</IPButton>

      <!-- Исследования -->
      <IPButton to="studies" variant="nav">{{ t('common.menu.studies') }}</IPButton>

      <!-- Админы -->
      <IPButton to="admins" variant="nav" v-if="check('admins')">
        {{ t('common.menu.admins') }}
      </IPButton>

      <!-- Пользователи -->
      <IPButton to="users" variant="nav">
        {{ t('common.menu.users') }}
      </IPButton>

      <!-- Логи -->
      <IPButton to="logs" variant="nav" v-if="check('logs')">
        {{ t('common.menu.logs') }}
      </IPButton>
    </div>

    <IPDropdown class="site-header__profile">
      <IconUser/>
      {{ userLabel }}

      <template v-slot:items>
        <!-- Профиль -->
        <div
          class="ip-dropdown__item"
          @click.self="handleOpenProfile"
          @keyup="handleOpenProfile"
        >
          {{ t('profile.menu.profile') }}
        </div>

        <!-- Выйти -->
        <div
          class="ip-dropdown__item"
          @click.self="handleLogout"
          @keyup="handleLogout"
        >
          {{ t('profile.menu.logout') }}
        </div>
      </template>
    </IPDropdown>

    <LanguageSwitcher/>
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { check } from '@/core/services/acl';

import LanguageSwitcher from '@/components/site/LanguageSwitcher.vue';

import IPDropdown from '@/core/ui/IPDropdown.vue';
import IPButton from '@/core/ui/buttons/IPButton.vue';
import IconUser from '@/core/ui/icons/IconUser.vue';

import LogoutRequest from '@/requests/auth/LogoutRequest';
import Store from '@/store';

export default {
  name: 'SiteHeader',

  components: {
    LanguageSwitcher,

    IPButton,
    IPDropdown,
    IconUser,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const { t, locale } = useI18n();

    const user = computed(() => store.state.user.info);

    const userLabel = computed(() => {
      let label = `${user.value?.last_name} ${user.value?.first_name[0]}`;
      if (user.value?.patronymic_name) {
        label += `.${user.value.patronymic_name[0]}.`;
      }

      return label;
    });

    const request = new LogoutRequest();

    async function handleLogout() {
      const response = await request.sendRequest();
      if (response.code === 200) {
        Store.commit('user/info', null);
        Store.commit('user/token', null);
        localStorage.removeItem('application.auth.token');
        await router.push({ name: 'login' });
      }
    }

    const showModalProfile = inject('showModalProfile');
    function handleOpenProfile() {
      showModalProfile.value = true;
    }

    return {
      locale,
      t,
      user,
      userLabel,
      handleOpenProfile,
      handleLogout,
      check,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.site-header {
  background: $color_white;
  height: 82px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 25px;

  &__brand {
    @include font_header;
  }

  &__nav {
    margin-left: 64px;

    .ip-button_nav {
      margin-right: 32px;
    }
  }

  &__profile {
    margin-left: auto;
    margin-right: 24px;
  }
}
</style>
