<template>
<div class="sponsor-list">
  <IPTable
    id="study"
    :request="request"
    :rules="rules"
    :creatable="check('import')"
    @create="handleImport"
    use-item-actions
    create-btn-text="common.buttons.import"
    router-name="studies"
  >
    <template v-slot:actions="{ item }">
      <!-- В архив -->
      <IPButton @click="handleArchive(item)" variant="nav" v-if="item.is_active">
        {{ t('study.buttons.archive') }}
      </IPButton>
      <!-- Из Архива -->
      <IPButton @click="handleUnarchive(item)" variant="nav" v-else>
        {{ t('study.buttons.unarchive') }}
      </IPButton>
    </template>
  </IPTable>
</div>
</template>

<script>
import { ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { check } from '@/core/services/acl';

import IPTable from '@/core/ui/table/IPTable.vue';
import IPButton from '@/core/ui/buttons/IPButton.vue';

import StudiesListRequest from '@/requests/studies/StudiesListRequest';
import EDCImportRequest from '@/requests/studies/EDCImportRequest';
import ArchiveRequest from '@/requests/studies/ArchiveRequest';
import UnarchiveRequest from '@/requests/studies/UnarchiveRequest';

export default {
  name: 'SponsorsList',

  components: {
    IPTable,
    IPButton,
  },

  setup() {
    const { t } = useI18n();
    const confirm = inject('confirm');
    const notification = inject('notification');

    const request = ref(new StudiesListRequest());

    const rules = {
      expected_total_enrollment: {
        typeFilter: 'range',
        align: 'right',
      },
      is_active: {
        typeFilter: 'select',
        sortable: false,
        searchable: true,
        options: [
          {
            label: t('study.status.all'),
            value: 'all',
          },
          {
            label: t('study.status.active'),
            value: 'active',
          },
          {
            label: t('study.status.archived'),
            value: 'archived',
          },
        ],
      },
    };

    async function handleArchive(item) {
      const isConfirmed = await confirm({
        title: t('study.confirms.archive.title'),
        description: t('study.confirms.archive.description'),
        confirmBtn: t('study.buttons.archive'),
        rejectBtn: t('common.buttons.cancel'),
      });

      if (isConfirmed) {
        const request = new ArchiveRequest(item.id);
        const response = await request.sendRequest();
        if (response.code === 200) {
          item.is_active = false;
          notification(
            t('study.messages.archived.title'),
            t('study.messages.archived.description'),
            'success',
          );
        }
      }
    }

    async function handleUnarchive(item) {
      const isConfirmed = await confirm({
        title: t('study.confirms.unarchive.title'),
        description: t('study.confirms.unarchive.description'),
        confirmBtn: t('study.buttons.unarchive'),
        rejectBtn: t('common.buttons.cancel'),
      });

      if (isConfirmed) {
        const request = new UnarchiveRequest(item.id);
        const response = await request.sendRequest();
        if (response.code === 200) {
          item.is_active = true;
          notification(
            t('study.messages.unarchived.title'),
            t('study.messages.unarchived.description'),
            'success',
          );
        }
      }
    }

    async function handleImport() {
      const isConfirmed = await confirm({
        title: t('study.confirms.import.title'),
        description: t('study.confirms.import.description'),
        confirmBtn: t('common.buttons.import'),
        rejectBtn: t('common.buttons.cancel'),
      });

      if (isConfirmed) {
        const request = new EDCImportRequest();
        const response = await request.sendRequest();
        if (response.code === 200) {
          notification(
            t('study.messages.imported.title'),
            t('study.messages.imported.description'),
            'success',
          );
        }
      }
    }

    return {
      t,
      request,
      rules,
      handleArchive,
      handleUnarchive,
      handleImport,
      check,
    };
  },
};
</script>
