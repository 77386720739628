<template>
  <svg
    class="icon icon-dropdown"
    :class="`icon-dropdown_direction-${direction}`"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3421_162)">
      <path
        d="M11.3333 3.99984L6.07071 9.26246C6.03166 9.30151 5.96834 9.30151 5.92929
        9.26246L0.666664 3.99984"
        stroke="#1B85FF"
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3421_162">
        <rect width="12" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconDropdown',

  props: {
    direction: {
      type: String,
      default: 'down',
      validator(value) {
        return [
          'up',
          'down',
          'left',
          'right',
        ].includes(value);
      },
    },
  },
};
</script>

<style lang="scss">
.icon-dropdown {
  transition: .2s ease-in-out;

  &_direction-up {
    transform: rotate(180deg);
  }

  &_direction-down {
    transform: rotate(0deg);
  }

  &_direction-left {
    transform: rotate(90deg);
  }

  &_direction-right {
    transform: rotate(270deg);
  }
}
</style>
