import Request from '@/core/services/request';

export default class RecoveryRequest extends Request {
  constructor() {
    super('POST', 'recovery', {
      code: {
        value: null,
      },
      password: {
        value: null,
      },
      password_repeat: {
        value: null,
      },
    });
  }
}
