<template>
  <StudiesModal
    :load-request="request"
    :save-request="submitRequest"
    use-go-back
    @go-back="goBack"
  />
</template>

<script>
import { ref, inject } from 'vue';

import StudiesModal from '@/components/studies/modal.vue';

import StudiesListRequest from '@/requests/users/StudiesListRequest';
import StudiesUpdateRequest from '@/requests/users/StudiesUpdateRequest';

export default {
  name: 'UsersStudies',

  components: {
    StudiesModal,
  },

  props: {
    sponsorId: {
      type: Number,
    },
  },

  setup(props) {
    const viewMode = inject('sponsorUsersModalView');

    const request = ref(new StudiesListRequest(props.sponsorId, viewMode.item.id));
    const submitRequest = ref(new StudiesUpdateRequest(props.sponsorId, viewMode.item.id));

    function goBack() {
      viewMode.view = 'list';
      viewMode.item = null;
    }

    return {
      request,
      submitRequest,
      goBack,
    };
  },
};
</script>
