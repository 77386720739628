<template>
  <StudiesModal :load-request="request" :save-request="submitRequest" @close="close"/>
</template>

<script>
import { ref } from 'vue';

import StudiesModal from '@/components/studies/modal.vue';

import StudiesListRequest from '@/requests/staff/StudiesListRequest';
import StudiesUpdateRequest from '@/requests/staff/StudiesUpdateRequest';

export default {
  name: 'SponsorStudies',

  components: {
    StudiesModal,
  },

  props: {
    item: {
      type: Number,
    },
  },

  setup(props, { emit }) {
    const request = ref(new StudiesListRequest(props.item.id));
    const submitRequest = ref(new StudiesUpdateRequest(props.item.id));

    function close() {
      emit('update:item', null);
      emit('update:modelValue', false);
    }

    return {
      request,
      submitRequest,
      close,
    };
  },
};
</script>
