<template>
  <div :class="className">
    <slot/>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'IPTableRow',

  props: {
    isHead: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const className = computed(() => {
      const styles = ['ip-table-row'];

      if (props.isHead) {
        styles.push('ip-table-row_head');
      }

      return styles;
    });

    return {
      className,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/colors";
@import "@/core/ui/assets/styles/kit/fonts";

.ip-table-row {
  display: flex;
  background: $color_white;
  margin-bottom: 6px;
  border-radius: 6px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: $color_super_light_grey;
  }

  &_head {
    user-select: none;
    @include font_subtitle;

    &:hover {
      background: $color_white;
    }
  }
}
</style>
