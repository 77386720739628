<template>
  <IPModal @close="close">
    <template v-slot:title>
      <span v-if="item">{{ item.name }}</span>
      <span v-else>{{ t('sponsor.modals.create') }}</span>
    </template>

    <IPForm
      v-if="request && countries"
      :submit-text="item ? 'common.buttons.confirm' : 'common.buttons.create'"
      @submit="submit"
      :request="request"
    >
      <IPCheckbox
        v-model="request.attributes.foreign.value"
      >
        {{ t('sponsor.labels.foreign_sponsor') }}
      </IPCheckbox>

      <IPInput
        id="sponsor-modify-tin"
        v-model="request.attributes.tin.value"
        :error="request.getError('tin')"
        label="sponsor.labels.tin"
        @blur="loadInfoForTin"
        :type="request.attributes.foreign.value ? 'text' : 'number'"
      />

      <IPInput
        id="sponsor-modify-form__name"
        v-model="request.attributes.name.value"
        :error="request.getError('name')"
        label="sponsor.labels.name"
        :disabled="!request.attributes.foreign.value"
      />

      <IPInput
        id="sponsor-modify-legal_address"
        v-model="request.attributes.legal_address.value"
        :error="request.getError('legal_address')"
        label="sponsor.labels.legal_address"
        :disabled="!request.attributes.foreign.value"
      />

      <IPCheckbox v-model="matches">{{ t('sponsor.labels.actual_fact_address') }}</IPCheckbox>

      <IPInput
        id="sponsor-modify-actual_address"
        v-model="request.attributes.actual_address.value"
        :error="request.getError('actual_address')"
        label="sponsor.labels.actual_address"
        :disabled="matches"
      />

      <IPSelect
        v-model="request.attributes.country_id.value"
        :options="countries"
        label="sponsor.labels.country"
        attribute-label="name"
        attribute-value="id"
        :error="request.getError('country_id')"
      />
    </IPForm>
  </IPModal>
</template>

<script>
import { ref, inject, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import IPModal from '@/core/ui/IPModal.vue';
import IPForm from '@/core/ui/form/IPForm.vue';
import IPInput from '@/core/ui/form/IPInput.vue';
import IPCheckbox from '@/core/ui/form/IPCheckbox.vue';
import IPSelect from '@/core/ui/form/IPSelect.vue';

import SponsorsCreateRequest from '@/requests/sponsors/SponsorsCreateRequest';
import SponsorsUpdateRequest from '@/requests/sponsors/SponsorsUpdateRequest';
import SponsorsTinRequest from '@/requests/sponsors/SponsorsTinRequest';

import CountriesRequest from '@/requests/countries/CountriesRequest';

export default {
  name: 'SponsorModify',

  emits: ['save'],

  components: {
    IPModal,
    IPForm,
    IPInput,
    IPCheckbox,
    IPSelect,
  },

  props: {
    item: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const notification = inject('notification');
    const matches = ref(false);

    const countries = ref([]);
    const countriesRequest = new CountriesRequest();
    async function loadCountries() {
      const response = await countriesRequest.sendRequest();
      if (response.code === 200) {
        countries.value = response.data;
      }
    }
    loadCountries();

    const request = ref(null);
    let scenario;
    if (props.item) {
      request.value = new SponsorsUpdateRequest(props.item.id);
      request.value.setAttributes(props.item);
      scenario = 'updated';
    } else {
      request.value = new SponsorsCreateRequest();
      scenario = 'created';
    }

    function close() {
      request.value.clearAttributes();
      matches.value = false;
      emit('update:item', null);
      emit('update:modelValue', false);
    }

    async function loadInfoForTin(value) {
      if (!request.value.attributes.foreign.value) {
        request.value.clearErrors();
        const requestTin = new SponsorsTinRequest(value);
        const response = await requestTin.sendRequest();

        if (response.code === 400) {
          request.value.setError('tin', response.errors[0].messages[0]);
        }

        if (response.code === 200) {
          request.value.setAttribute('name', response.data.name);
          request.value.setAttribute('legal_address', response.data.legal_address);
          request.value.setAttribute('country_id', response.data.country_id);
          request.value.setAttribute('actual_address', null);
          matches.value = false;
        }
      }
    }

    watch(
      () => matches.value,
      (value) => {
        if (value) {
          request.value.setAttribute(
            'actual_address',
            request.value.attributes.legal_address.value,
          );
        }
      },
    );

    watch(
      () => request.value.attributes.foreign.value,
      (value) => {
        if (!value && request.value.attributes.tin.value) {
          loadInfoForTin(request.value.attributes.tin.value);
        }
      },
    );

    async function submit() {
      const response = await request.value.sendRequest();

      if (response.code === 200) {
        notification(
          t(`sponsor.messages.${scenario}.title`),
          t(
            `sponsor.messages.${scenario}.description`,
            { name: request.value.attributes.name.value },
          ),
          'success',
        );
        emit('save');
        close();
      }
    }

    watch(
      () => props.item,
      (value) => {
        if (value) {
          request.value = new SponsorsUpdateRequest(props.item.id);
          request.value.setAttributes(props.item);
          request.value.setAttribute('country_id', props.item.country.id);
          scenario = 'updated';
        } else {
          request.value = new SponsorsCreateRequest();
          scenario = 'created';
        }
      },
    );

    return {
      t,
      matches,
      countries,
      request,
      submit,
      close,
      loadInfoForTin,
    };
  },
};
</script>
