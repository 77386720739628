<template>
  <IPModal size="sm" class="modal-user-studies" @close="close" @keydown="close">
    <template v-slot:title>
      <div v-if="useGoBack" @click="goBack" @keydown="goBack"><IconBack/></div>
      <div>{{ t('study.modals.studies') }}</div>
    </template>

    <IPLoader v-if="loadRequest.isProcessing()"/>

    <IPForm
      v-if="loadRequest"
      submit-text="common.buttons.confirm"
      class="sponsor-modify-form"
      @submit="submit"
      :request="loadRequest"
    >
      <template v-if="linked && linked.length > 0">
        <div class="label">{{ t('study.labels.linked') }}</div>
        <IPCheckbox
          v-for="item in linked"
          v-model="item.is_linked"
          :key="item.id"
        >
          {{ item.name }}
        </IPCheckbox>
      </template>

      <template v-if="available && available.length > 0">
        <div class="label">{{ t('study.labels.available') }}</div>
        <IPCheckbox
          v-for="item in available"
          v-model="item.is_linked"
          :key="item.id"
        >
          {{ item.name }}
        </IPCheckbox>
      </template>
    </IPForm>
  </IPModal>
</template>

<script>
import { ref, inject } from 'vue';
import { map, filter } from 'lodash';
import { useI18n } from 'vue-i18n';

import IconBack from '@/core/ui/icons/IconBack.vue';
import IPLoader from '@/core/ui/IPLoader.vue';
import IPModal from '@/core/ui/IPModal.vue';
import IPCheckbox from '@/core/ui/form/IPCheckbox.vue';
import IPForm from '@/core/ui/form/IPForm.vue';

import Request from '@/core/services/request';

export default {
  name: 'StudiesModal',

  emits: ['go-back', 'close'],

  components: {
    IPLoader,
    IPModal,
    IPCheckbox,
    IPForm,
    IconBack,
  },

  props: {
    loadRequest: {
      type: Request,
      required: true,
    },

    saveRequest: {
      type: Request,
      required: true,
    },

    useGoBack: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const notification = inject('notification');

    const available = ref([]);
    const linked = ref([]);

    async function loadItems() {
      const response = await props.loadRequest.sendRequest();
      if (response.code === 200) {
        available.value = response.data.available;
        linked.value = response.data.linked;
      }
    }
    loadItems();

    async function submit() {
      const a = map(filter(available.value, (item) => item.is_linked), (item) => item.id);
      const b = map(filter(linked.value, (item) => item.is_linked), (item) => item.id);

      const ids = a.concat(b);

      props.saveRequest.setAttribute('studies', ids);

      const response = await props.saveRequest.sendRequest();
      if (response.code === 200) {
        notification(
          t('study.messages.linked.title'),
          t('study.messages.linked.description'),
          'success',
        );
        emit('update:modalValue', false);

        await loadItems();
      }
    }

    function goBack() {
      emit('go-back');
    }

    function close() {
      emit('close');
    }

    return {
      t,
      available,
      linked,
      submit,
      goBack,
      close,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.modal-user-studies {
  .ip-modal__title {
    display: flex;
    justify-content: left;

    div:first-child {
      cursor: pointer;
    }
  }

  .label {
    @include font_inputs;
    color: $color_main_black;
    margin-bottom: 6px;
  }
}
</style>
