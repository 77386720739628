<template>
<div class="ip-checkbox" @click="toggle" @keyup="toggle">
  <div class="ip-checkbox__toggle">
    <svg
      v-if="modelValue"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="5" fill="#1B85FF"/>
      <path
        d="M13 6L8.56894 11.9078C8.53243 11.9564 8.46126 11.9615 8.41823 11.9185L5 8.5"
        stroke="white"
        stroke-linecap="round"
      />
    </svg>

    <svg
      v-else
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.6"
        y="0.6"
        width="16.8"
        height="16.8"
        rx="4.4"
        stroke="#9D9D9D"
        stroke-width="1.2"
      />
    </svg>
  </div>

  <div class="ip-checkbox__label"><slot/></div>
</div>
</template>

<script>
export default {
  name: 'IPCheckbox',

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    function toggle() {
      emit('update:modelValue', !props.modelValue);
    }

    return {
      toggle,
    };
  },
};
</script>

<style lang="scss">
  .ip-checkbox {
    display: flex;
    cursor: pointer;

    &__label {
      margin-left: 10px;
    }
  }
</style>
