// eslint-disable-next-line
import Request from '@/core/services/request';

export default class ValidateRequest extends Request {
  constructor() {
    super('POST', 'validate', {
      form: {
        value: null,
      },
      field: {
        value: null,
      },
      value: {
        value: null,
      },
      attribute_id: {
        value: null,
      },
      scenario: {
        value: null,
      },
    });
  }
}
