<template>
  <div class="ip-notification">
    <div
      v-for="item in items"
      :class="getClass(item)"
      :key="item.index"
    >
      <IconSuccess v-if="item.type === 'success'"/>
      <IconError v-if="item.type === 'error'"/>

      <div class="ip-notification__message">
        <div class="ip-notification__title">
          {{ item.title }}
        </div>
        <div class="ip-notification__description">
          {{ item.description }}
        </div>
      </div>

      <div class="ip-notification__close">
        <IconClose @click="remove(item.index)"/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import IconSuccess from '@/core/plugins/notification/icons/IconSuccess.vue';
import IconError from '@/core/plugins/notification/icons/IconError.vue';
import IconClose from '@/core/ui/icons/IconClose.vue';

const items = ref({});

export function remove(index) {
  delete items.value[index];
}

export function add(title, description, type = 'info', options = {}) {
  const duration = options.duration || 3000;

  const date = new Date();
  const index = date.getTime() + date.getMilliseconds();

  items.value[index] = {
    index,
    title,
    description,
    type,
    options,
  };

  setTimeout(() => remove(index), duration);
}

export default {
  name: 'IPNotifications',

  components: {
    IconSuccess,
    IconError,
    IconClose,
  },

  setup() {
    const { t } = useI18n();

    function getClass(item) {
      return ['ip-notification__item', `ip-notification__item_${item.type}`];
    }

    return {
      t,
      items,
      getClass,
      add,
      remove,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.ip-notification {
  position: fixed;
  right: 24px;
  bottom: 24px;
  width: 300px;
  z-index: 900000;

  &__item {
    background: $color_white;
    border: 1px solid $color_mid_grey;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 20px;

    display: flex;
  }

  &__message {
    margin-left: 16px;
  }

  &__title {
    @include font_subtitle;

    color: $color_main_black;
    margin-bottom: 16px;
  }

  &__description {

  }

  &__close {
    svg {
      cursor: pointer;
    }
  }
}
</style>
