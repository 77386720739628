<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_2809_31377" fill="white">
      <path
        d="M7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9C11 10.1046 10.1046 11 9 11C7.89543
        11 7 10.1046 7 9Z"
      />
    </mask>
    <path
      d="M7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9C11 10.1046 10.1046 11 9 11C7.89543
      11 7 10.1046 7 9Z"
      fill="#9D9D9D"
    />
    <path
      d="M12 9C12 10.6569 10.6569 12 9 12L9 2C5.13401 2 2 5.13401 2 9L12 9ZM9 12C7.34315 12 6
      10.6569 6 9L16 9C16 5.13401 12.866 2 9 2L9 12ZM6 9C6 7.34315 7.34315 6 9 6L9 16C12.866 16 16
      12.866 16 9L6 9ZM9 6C10.6569 6 12 7.34315 12 9L2 9C2 12.866 5.13401 16 9 16L9 6Z"
      fill="#9D9D9D"
      mask="url(#path-1-inside-1_2809_31377)"
    />
    <mask id="path-3-inside-2_2809_31377" fill="white">
      <path
        d="M7 15C7 13.8954 7.89543 13 9 13C10.1046 13 11 13.8954 11 15C11 16.1046 10.1046 17 9
        17C7.89543 17 7 16.1046 7 15Z"
      />
    </mask>
    <path
      d="M7 15C7 13.8954 7.89543 13 9 13C10.1046 13 11 13.8954 11 15C11 16.1046 10.1046 17 9
      17C7.89543 17 7 16.1046 7 15Z"
      fill="#9D9D9D"
    />
    <path
      d="M12 15C12 16.6569 10.6569 18 9 18L9 8C5.13401 8 2 11.134 2 15L12 15ZM9 18C7.34315 18 6
      16.6569 6 15L16 15C16 11.134 12.866 8 9 8L9 18ZM6 15C6 13.3431 7.34315 12 9 12L9 22C12.866 22
      16 18.866 16 15L6 15ZM9 12C10.6569 12 12 13.3431 12 15L2 15C2 18.866 5.13401 22 9 22L9 12Z"
      fill="#9D9D9D"
      mask="url(#path-3-inside-2_2809_31377)"
    />
    <mask id="path-5-inside-3_2809_31377" fill="white">
      <path
        d="M7 3C7 1.89543 7.89543 1 9 1C10.1046 1 11 1.89543 11 3C11 4.10457 10.1046 5 9 5C7.89543
        5 7 4.10457 7 3Z"
      />
    </mask>
    <path
      d="M7 3C7 1.89543 7.89543 1 9 1C10.1046 1 11 1.89543 11 3C11 4.10457 10.1046 5 9 5C7.89543 5
      7 4.10457 7 3Z"
      fill="#9D9D9D"
    />
    <path
      d="M12 3C12 4.65685 10.6569 6 9 6L9 -4C5.13401 -4 2 -0.865994 2 3L12 3ZM9 6C7.34315 6 6
      4.65685 6 3L16 3C16 -0.865993 12.866 -4 9 -4L9 6ZM6 3C6 1.34315 7.34315 -3.19692e-07 9
      -1.74846e-07L9 10C12.866 10 16 6.86599 16 3L6 3ZM9 -1.74846e-07C10.6569 -2.99988e-08 12
      1.34315 12 3L2 3C2 6.86599 5.13401 10 9 10L9 -1.74846e-07Z"
      fill="#9D9D9D"
      mask="url(#path-5-inside-3_2809_31377)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconItemMenu',
};
</script>
