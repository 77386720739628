<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3606_4698)">
      <path
        d="M11 3L5.5702 9.41704C5.53241 9.4617 5.46452 9.46452 5.42315 9.42315L1.5 5.5"
        stroke="#1B85FF"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3606_4698">
        <rect width="12" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSelected',
};
</script>
