<template>
  <div class="ip-table">
    <div class="ip-table__data">
      <!-- Заголовок таблицы -->
      <IPTableRow is-head>
        <IPTableCell
          v-for="head in headers"
          :sortable="head.isSortable"
          v-model:sort-direction="filters.sort_direction"
          v-model:sort-field="filters.sort_field"
          :name="head.name"
          :key="head.name"
          :align="head.fieldAlign"
        >
          <span v-if="head.title">{{ t(head.title) }}</span>
        </IPTableCell>
        <IPTableCell name="action" v-if="useItemActions"></IPTableCell>
      </IPTableRow>

      <!-- Контент -->
      <IPTableRow v-for="item in items" :key="item.id">
        <IPTableCell v-for="(value, key) in item" :name="key" :align="getAlign(key)" :key="key">
          <template v-if="key === 'status'">
            {{ t(`${id}.statuses.${getValue(value, key)}`) }}
          </template>
          <template v-else-if="key === 'is_active'">
            {{ t(`common.${getValue(value, key)}`) }}
          </template>
          <template v-else>
            {{ getValue(value, key) }}
          </template>
        </IPTableCell>
        <IPTableCell name="action" v-if="useItemActions">
          <IPDropdown minimal>
            <IconItemMenu/>

            <template v-slot:items>
              <slot name="actions" :item="item"/>
            </template>
          </IPDropdown>
        </IPTableCell>
      </IPTableRow>

      <!-- Loading -->
      <IPLoader v-if="request.isProcessing()"/>

      <IPPagination
        v-if="pagination.totalCount > pagination.count"
        :total="pagination.totalCount"
        v-model:page="filters.page"
      />
    </div>

    <div class="ip-table__actions">
      <IPButton v-if="creatable" variant="primary-outline" adaptive size="sm" @click="handleCreate">
        {{ t(createBtnText) }}
      </IPButton>

      <div class="ip-table__filters">
        {{ t('common.labels.search_attributes') }}

        <div class="ip-table__filter">
          <template v-for="head in headers" :key="head.name">
            <template v-if="head.isSearchable">
              <template v-if="head.typeFilter === 'range'">
                <IPInput
                  :id="`${head.title}_min`"
                  :label="`${head.title}_min`"
                  v-model="filters[`${head.name}_min`]"
                  type="number"
                />

                <IPInput
                  :id="`${head.title}_max`"
                  :label="`${head.title}_max`"
                  v-model="filters[`${head.name}_max`]"
                  type="number"
                />
              </template>

              <IPSelect
                v-else-if="head.typeFilter === 'select'"
                :label="head.title"
                v-model="filters[head.name]"
                :options="head.optionsFilter"
              />

              <IPInput
                v-else
                :id="head.name"
                :label="head.title"
                v-model="filters[head.name]"
                :type="head.name === 'id' || head.name === 'tin' ? 'number' : 'text'"
              />
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { each, isObject } from 'lodash';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';

import IPTableRow from '@/core/ui/table/IPTableRow.vue';
import IPTableCell from '@/core/ui/table/IPTableCell.vue';
import IPDropdown from '@/core/ui/IPDropdown.vue';
import IPPagination from '@/core/ui/IPPagination.vue';
import IPInput from '@/core/ui/form/IPInput.vue';
import IconItemMenu from '@/core/ui/icons/IconItemMenu.vue';

import Request from '@/core/services/request';
import IPLoader from '@/core/ui/IPLoader.vue';
import IPButton from '@/core/ui/buttons/IPButton.vue';
import IPSelect from '@/core/ui/form/IPSelect.vue';

export default {
  name: 'IPTable',

  emits: ['create'],

  components: {
    IPSelect,
    IPButton,
    IPLoader,
    IPTableRow,
    IPTableCell,
    IPDropdown,
    IPPagination,
    IPInput,
    IconItemMenu,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    request: {
      type: Request,
      required: true,
    },

    rules: {
      type: Object,
      default() {
        return {};
      },
    },

    routerName: {
      type: String,
      required: true,
    },

    creatable: {
      type: Boolean,
      default: false,
    },

    createBtnText: {
      type: String,
      default: 'common.buttons.create',
    },

    useItemActions: {
      type: Boolean,
      default: false,
    },

    deleted: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();

    const items = ref([]);
    const headers = ref([]);
    const pagination = ref({
      totalCount: 0,
      count: 0,
    });
    const { t } = useI18n();

    const filters = ref({
      page: Number(route.query.page) || 1,
      sort_field: route.query.sort_field || 'id',
      sort_direction: route.query.sort_direction || 'asc',
    });

    async function loadItems() {
      props.request.setAttributes(filters.value);

      const response = await props.request.sendRequest();
      if (response.code === 200) {
        items.value = response.data.items;
        pagination.value = response.data.pagination;

        // Создаем заголовки и фильтры
        if (headers.value.length === 0 && response.data.items.length > 0) {
          each(response.data.items[0], (item, key) => {
            let sortable = true;
            let searchable = true;
            let typeFilter = 'input';
            let fieldAlign = 'left';
            let optionsFilter = [];
            const rule = props.rules ? props.rules[key] : null;

            if (rule) {
              sortable = typeof rule.sortable !== 'undefined' ? rule.sortable : true;
              searchable = typeof rule.searchable !== 'undefined' ? rule.searchable : true;
              typeFilter = typeof rule.typeFilter !== 'undefined' ? rule.typeFilter : 'input';
              optionsFilter = typeof rule.options !== 'undefined' ? rule.options : [];
              fieldAlign = typeof rule.align !== 'undefined' ? rule.align : 'left';
            }

            headers.value.push({
              name: key,
              title: `${props.id}.table.${key}`,
              isSortable: sortable,
              isSearchable: searchable,
              typeFilter,
              fieldAlign,
              optionsFilter,
            });

            if (searchable) {
              filters.value[key] = route.query[key];
            }
          });
        }
      }
    }
    loadItems();

    function getValue(item, key) {
      if (isObject(item) && props.rules[key]) {
        return item[props.rules[key].title];
      }

      return item;
    }

    function getAlign(key) {
      if (props.rules && props.rules[key] && props.rules[key].align) {
        return props.rules[key].align;
      }

      return 'left';
    }

    const timeout = ref(null);
    watch(filters.value, (value) => {
      clearTimeout(timeout.value);
      timeout.value = setTimeout(() => {
        router.push({ name: props.routerName, query: value });
        loadItems();
      }, 500);
    });

    watch(() => props.deleted, (value) => {
      if (value) {
        emit('update:deleted', false);
        loadItems();
      }
    });

    function handleCreate() {
      emit('create');
    }

    return {
      t,
      headers,
      filters,
      items,
      pagination,
      getValue,
      handleCreate,
      getAlign,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/colors";
@import "@/core/ui/assets/styles/kit/fonts";

.ip-table {
  display: flex;

  &__data {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 300px;

    .ip-pagination {
      margin-top: 25px;
    }
  }

  &__actions {
    margin-left: 25px;

    .ip-button {
      margin-bottom: 15px;
    }
  }

  &__filters {
    @include font_subtitle;

    border-radius: 6px;
    height: fit-content;
    width: 300px;
    padding: 15px;
    background: $color_white;

    .ip-input {
      margin-top: 15px;
    }
  }
}
</style>
