<template>
  <IPModal @close="close">
    <template v-slot:title>
      <div class="modal-user-modify__title">
        <div v-if="item">{{ item.first_name }}</div>
        <div v-else>{{ t('employee.modals.new_user') }}</div>
      </div>
    </template>

    <div v-if="!item" class="model-user__new-description">
      {{ t('employee.messages.invitation') }}
    </div>

    <IPForm
      v-if="request"
      :submit-text="item ? 'common.buttons.confirm' : 'common.buttons.create'"
      class="sponsor-modify-form"
      @submit="submit"
      :request="request"
    >
      <IPInput
        id="sponsor-modify-form__last_name"
        v-model="request.attributes.last_name.value"
        :error="request.getError('last_name')"
        label="user.labels.last_name"
        @blur="validator(request, 'last_name')"
      />

      <IPInput
        id="sponsor-modify-form__first_name"
        v-model="request.attributes.first_name.value"
        :error="request.getError('first_name')"
        label="user.labels.first_name"
        @blur="validator(request, 'first_name')"
      />

      <IPInput
        id="sponsor-modify-form__patronymic_name"
        v-model="request.attributes.patronymic_name.value"
        :error="request.getError('patronymic_name')"
        label="user.labels.patronymic_name"
        @blur="validator(request, 'patronymic_name')"
      />

      <IPInput
        id="sponsor-modify-form__email"
        v-model="request.attributes.email.value"
        :error="request.getError('email')"
        label="user.labels.email"
        @blur="validator(request, 'email')"
      />

      <IPInput
        id="sponsor-modify-form__phone"
        v-model="request.attributes.phone.value"
        :error="request.getError('phone')"
        label="user.labels.phone"
        @blur="validator(request, 'phone')"
      />
    </IPForm>
  </IPModal>
</template>

<script>
import { inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { validator } from '@/core/services/validator';

import IPModal from '@/core/ui/IPModal.vue';
import IPForm from '@/core/ui/form/IPForm.vue';
import IPInput from '@/core/ui/form/IPInput.vue';

import AdminCreateRequest from '@/requests/admin/AdminCreateRequest';
import AdminUpdateRequest from '@/requests/admin/AdminUpdateRequest';

export default {
  name: 'AdminModify',

  emits: ['save'],

  components: {
    IPModal,
    IPInput,
    IPForm,
  },

  props: {
    item: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const notification = inject('notification');

    const request = ref(null);
    let scenario;
    if (props.item) {
      request.value = new AdminUpdateRequest(props.item.id);
      request.value.setAttributes(props.item);
      scenario = 'updated';
    } else {
      request.value = new AdminCreateRequest();
      scenario = 'created';
    }

    function close() {
      request.value.clearAttributes();
      emit('update:modelValue', false);
    }

    async function submit() {
      const response = await request.value.sendRequest();

      if (response.code === 200) {
        notification(
          t(`user.messages.${scenario}.title`),
          t(`user.messages.${scenario}.description`, { name: request.value.attributes.email.value }),
          'success',
        );
        emit('save');
        close();
      }
    }

    watch(
      () => props.item,
      (value) => {
        if (value) {
          request.value = new AdminUpdateRequest(props.item.id);
          request.value.setAttributes(props.item);
          scenario = 'updated';
        } else {
          request.value = new AdminCreateRequest();
          scenario = 'created';
        }
      },
    );

    return {
      t,
      request,
      submit,
      close,
      validator,
    };
  },
};
</script>
