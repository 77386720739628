<template>
  <IPModal @close="close">
    <template v-slot:title>
      <div class="modal-user-modify__title">
        <div v-if="item">{{ item.first_name }}</div>
        <div v-else>{{ t('employee.modals.new_user') }}</div>
      </div>
    </template>

    <div v-if="!item" class="model-user__new-description">
      {{ t('employee.messages.invitation') }}
    </div>

    <IPForm
      v-if="request"
      :submit-text="item ? 'common.buttons.confirm' : 'common.buttons.create'"
      class="sponsor-modify-form"
      @submit="submit"
      :request="request"
    >
      <IPInput
        id="sponsor-modify-form__last_name"
        v-model="request.attributes.last_name.value"
        :error="request.getError('last_name')"
        label="user.labels.last_name"
        @blur="validator(request, 'last_name')"
      />

      <IPInput
        id="sponsor-modify-form__first_name"
        v-model="request.attributes.first_name.value"
        :error="request.getError('first_name')"
        label="user.labels.first_name"
        @blur="validator(request, 'first_name')"
      />

      <IPInput
        id="sponsor-modify-form__patronymic_name"
        v-model="request.attributes.patronymic_name.value"
        :error="request.getError('patronymic_name')"
        label="user.labels.patronymic_name"
        @blur="validator(request, 'patronymic_name')"
      />

      <IPInput
        id="sponsor-modify-form__email"
        v-model="request.attributes.email.value"
        :error="request.getError('email')"
        label="user.labels.email"
        @blur="validator(request, 'email')"
      />

      <IPInput
        id="sponsor-modify-form__phone"
        v-model="request.attributes.phone.value"
        :error="request.getError('phone')"
        label="user.labels.phone"
        @blur="validator(request, 'phone')"
      />

      <IPSelect
        v-model="request.attributes.position.value"
        :error="request.getError('position')"
        :options="positions"
        attributeLabel="title"
      />

      <IPInput
        type="file"
        id="sponsor-modify-form__photo"
        @change="handleUpload"
        label="user.labels.photo"
      />
    </IPForm>
  </IPModal>
</template>

<script>
import { inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { validator } from '@/core/services/validator';

import IPModal from '@/core/ui/IPModal.vue';
import IPForm from '@/core/ui/form/IPForm.vue';
import IPInput from '@/core/ui/form/IPInput.vue';
import IPSelect from '@/core/ui/form/IPSelect.vue';

import StaffCreateRequest from '@/requests/staff/StaffCreateRequest';
import StaffUpdateRequest from '@/requests/staff/StaffUpdateRequest';
import PositionsListRequest from '@/requests/staff/PositionsListRequest';

export default {
  name: 'StaffModify',

  emits: ['save'],

  components: {
    IPSelect,
    IPModal,
    IPInput,
    IPForm,
  },

  props: {
    item: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const notification = inject('notification');

    const request = ref(null);
    let scenario;
    if (props.item) {
      request.value = new StaffUpdateRequest(props.item.id);
      request.value.setAttributes(props.item);
      scenario = 'updated';
    } else {
      request.value = new StaffCreateRequest();
      scenario = 'created';
    }

    const positions = ref([]);
    const handleLoadPositions = async () => {
      const request = ref(new PositionsListRequest());
      const response = await request.value.sendRequest();
      if (response.code === 200) {
        positions.value = response.data.items;
      }
    };
    handleLoadPositions();

    function close() {
      request.value.clearAttributes();
      emit('update:modelValue', false);
    }

    const file = ref(null);
    const handleUpload = (event) => {
      const input = event.target;
      if (input.files) {
        const [firstFile] = input.files;
        file.value = firstFile;
      }
    };

    async function submit() {
      request.value.attributes = request.value.attributes || {};
      request.value.attributes.photo = {};
      request.value.attributes.photo.value = file;
      const response = await request.value.sendFormData();

      if (response.code === 200) {
        notification(
          t(`user.messages.${scenario}.title`),
          t(`user.messages.${scenario}.description`, { name: request.value.attributes.email.value }),
          'success',
        );
        emit('save');
        close();
      }
    }

    watch(
      () => props.item,
      (value) => {
        if (value) {
          request.value = new StaffUpdateRequest(props.item.id);
          request.value.setAttributes(props.item);
          scenario = 'updated';
        } else {
          request.value = new StaffCreateRequest();
          scenario = 'created';
        }
      },
    );

    return {
      t,
      request,
      submit,
      close,
      validator,
      positions,
      handleUpload,
    };
  },
};
</script>
