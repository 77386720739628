<template>
  <router-view/>

  <IPNotifications/>
</template>

<script>
import { inject, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import IPNotifications from '@/core/plugins/notification/IPNotification.vue';

export default {
  components: {
    IPNotifications,
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();

    const notification = inject('notification');

    const errorMessage = computed(() => store.state.errorHandler.message);
    watch(
      () => errorMessage.value,
      (value) => {
        notification(
          t('common.labels.error'),
          value,
          'error',
        );
      },
    );
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/application";
</style>
