import Request from '@/core/services/request';

export default class SponsorsCreateRequest extends Request {
  constructor() {
    super('POST', 'sponsors', {
      name: {
        value: null,
      },
      legal_address: {
        value: null,
      },
      actual_address: {
        value: null,
      },
      tin: {
        value: null,
      },
      country_id: {
        value: null,
      },
      foreign: {
        value: false,
      },
    });
  }
}
