<template>
  <div
    :class="className"
    @click="handleFocus"
    tabindex="0"
    @focus="handleFocus"
    @keyup="handleFocus"
  >
    <label :for="id">
      <div class="ip-input__container">
        <div class="ip-input__box">
          <div class="ip-input__label" v-if="error">{{ t(error) }}</div>
          <div class="ip-input__label" v-else-if="label">{{ t(label) }}</div>

          <input
            ref="realInput"
            :id="id"
            v-model="value"
            :type="realTypeInput"
            @blur="handleBlur"
            @keydown="check"
            :disabled="disabled"
          >
        </div>

        <div class="ip-input__postfix-icon">
          <!-- Icon type password -->
          <IconEye
            :visible="passwordVisible"
            @click="togglePassword"
            v-if="type === 'password'"
          />
        </div>
      </div>
    </label>
    <div class="ip-input__subtitle">
      <slot name="subtitle">{{ t(subtitle) }}</slot>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import IconEye from '@/core/ui/icons/IconEye.vue';

export default {
  name: 'IPInput',

  emits: ['blur', 'focus'],

  components: {
    IconEye,
  },

  props: {
    modelValue: null,

    id: {
      type: String,
      required: true,
    },

    label: {
      type: String,
    },

    type: {
      type: String,
      validate(value) {
        return [
          'text',
          'email',
          'phone',
          'number',
          'password',
          'file',
        ].includes(value);
      },
    },

    error: {
      type: String,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    subtitle: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const isFocused = ref(false);

    const value = computed({
      get() {
        return props.modelValue;
      },

      set(val) {
        emit('update:modelValue', props.type === 'number' ? val.replace(/[^0-9]/g, '') : val);
      },
    });

    const passwordVisible = ref(false);

    function togglePassword() {
      passwordVisible.value = !passwordVisible.value;
    }

    const realTypeInput = computed(() => {
      if (props.type === 'password' && passwordVisible.value === true) {
        return 'text';
      }

      return props.type;
    });

    const className = computed(() => {
      const styles = ['ip-input'];

      if (props.disabled) {
        styles.push('ip-input_disabled');
      }

      if (isFocused.value === true) {
        styles.push('ip-input_focused');
      }

      if (props.error) {
        styles.push('ip-input_invalid');
      }

      if (props.disabled) {
        styles.push('ip-input_disabled');
      }

      return styles;
    });

    const realInput = ref(null);
    function handleFocus() {
      realInput.value.focus();
      isFocused.value = true;
    }

    function handleBlur() {
      emit('blur', props.modelValue);
      isFocused.value = false;
    }

    function check(e) {
      if (props.type === 'number') {
        // Detecting Ctrl
        const ctrl = e.ctrlKey ? e.ctrlKey : e.keyCode === 17;
        let prevent = true;

        if (
          (e.keyCode >= 48 && e.keyCode <= 57)
          || (e.keyCode >= 96 && e.keyCode <= 105)
          || e.keyCode === 8
          || e.keyCode === 46
          || e.keyCode === 37
          || e.keyCode === 39
          || e.keyCode === 9
          || (ctrl && e.keyCode === 86)
          || (ctrl && e.keyCode === 67)
          || (ctrl && e.keyCode === 65)
        ) {
          prevent = false;
        }

        if (prevent) {
          e.preventDefault();
        }
      }

      return true;
    }

    return {
      t,
      isFocused,
      className,
      value,

      passwordVisible,
      togglePassword,
      realTypeInput,

      check,
      realInput,
      handleFocus,
      handleBlur,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/kit/fonts";
@import "@/core/ui/assets/styles/kit/colors";

.ip-input {
  width: 100%;
  position: relative;
  cursor: text;

  label {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: start;
  }

  &_focused, :not(input[value="a"]) + .ip-input__label {
    .ip-input__label {
      @include font_title_inputs;

      //transform: translate(0, -100%);
    }
  }

  &__label {
    //position: absolute;
    //top: 25%;
    //left: 0;

    @include font_inputs;

    user-select: none;
    color: $color_grey_outline;
    cursor: text;
    //margin-left: 16px;

    transition:
      transform 0.15s ease-out,
      font-size 0.15s ease-out,
      background-color 0.2s ease-out,
      color 0.15s ease-out;

    //transform: translate(0);
  }

  &__container {
    position: relative;

    display: flex;
    align-items: center;
    border: 1px solid #ADADAD;
    border-radius: 10px;
    padding: 0 16px;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    cursor: text;
  }

  &__box {
    flex: 1;
    cursor: text;
  }

  &__postfix-icon {
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    border: none;

    width: 100%;
    height: 100%;

    &:active, &:focus {
      outline: none;
    }
  }

  &_invalid {
    .ip-input__container {
      border-color: #EE5B6D;
    }

    .ip-input__label {
      color: #EE5B6D;
    }
  }

  &_disabled {
    cursor: default;
    background: $color_light_grey;
    border-radius: 10px;
    input {
      background: $color_light_grey;
    }
  }

  &__subtitle {
    cursor: default;
    user-select: none;

    @include font_details;
    color: $color_grey_outline;

    margin-top: 8px;
  }
}
</style>
