<template>
  <div class="ip-pagination">
    <!-- Предыдущая страницы -->
    <IPButton
      variant="primary-outline"
      @click="setPage(page - 1)"
      size="sm"
    >
      <IconDropdown direction="left" />
    </IPButton>

    <!-- Нумерация страниц -->
    <IPButton
      v-for="n in pageNumbers"
      :key="n"
      :variant="page === n ? 'primary' : 'primary-outline'"
      @click="setPage(n)"
      size="sm"
    >
      {{ n }}
    </IPButton>

    <!-- Следующая страница -->
    <IPButton
      variant="primary-outline"
      @click="setPage(page + 1)"
      size="sm"
    >
      <IconDropdown direction="right" />
    </IPButton>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import IPButton from '@/core/ui/buttons/IPButton.vue';
import IconDropdown from '@/core/ui/icons/IconDropdown.vue';

export default {
  name: 'IPPagination',

  emits: ['change-page'],

  components: {
    IPButton,
    IconDropdown,
  },

  props: {
    total: {
      type: [Number, String],
      default: 0,
    },

    page: {
      type: [Number, String],
      default: 1,
    },

    count: {
      type: [Number, String],
      default: 25,
    },

    displayPageCount: {
      type: Number,
      default: 10,
    },
  },

  setup(props, { emit }) {
    const totalPages = computed(() => Math.ceil(props.total / props.count) || 1);
    const averageCount = computed(() => Math.round((props.displayPageCount - 1) / 2));
    const lastPage = ref(null);

    const pageNumbers = computed(() => {
      const numbers = [];
      let start = 1;
      let end = totalPages.value;
      const currentPage = parseInt(props.page, 10);

      if (currentPage <= averageCount.value) {
        start = 1;
        end = props.displayPageCount;
      }

      if (currentPage > averageCount.value && currentPage < totalPages.value - averageCount.value) {
        start = currentPage - averageCount.value;
        end = currentPage + averageCount.value;
      }

      if (currentPage >= totalPages.value - averageCount.value) {
        start = totalPages.value - props.displayPageCount + 1;
        end = totalPages.value;
      }

      for (start; start <= end; start += 1) {
        if (start >= 1 && start <= totalPages.value) {
          numbers.push(start);
        }
      }

      return numbers;
    });

    function setPage(page) {
      if (page < 1) {
        return;
      }

      emit('update:page', page);
      emit('change-page', page);
    }

    return {
      pageNumbers,
      lastPage,
      setPage,
    };
  },
};
</script>

<style lang="scss">
.ip-pagination {
  display: flex;

  .ip-button {
    margin-right: 2px;
  }
}
</style>
