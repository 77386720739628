<template>
  <div class="page page_login">
    <IPForm
      submit-text="auth.buttons.login"
      class="login-form"
      @submit="submit"
      :request="request"
    >
      <IPInput
        id="login_form__email"
        v-model="request.attributes.email.value"
        :error="request.getError('email')"
        label="auth.labels.email"
        type="email"
        @blur="validator(request, 'email')"
      />
      <IPInput
        id="login_form__password"
        v-model="request.attributes.password.value"
        :error="request.getError('password')"
        label="auth.labels.password"
        type="password"
        @blur="validator(request, 'password')"
      >
        <template v-slot:subtitle>
          <IPButton
            variant="link"
            class="login_form__forgot-link"
            :to="{ name: 'forgot' }"
          >
            {{ t('auth.buttons.forgot') }}
          </IPButton>
        </template>
      </IPInput>
    </IPForm>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { validator } from '@/core/services/validator';

import IPButton from '@/core/ui/buttons/IPButton.vue';
import IPForm from '@/core/ui/form/IPForm.vue';
import IPInput from '@/core/ui/form/IPInput.vue';
import LoginRequest from '@/requests/auth/LoginRequest';

export default {
  name: 'LoginPage',

  components: {
    IPForm,
    IPInput,
    IPButton,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();

    const request = ref(new LoginRequest());
    async function submit() {
      const response = await request.value.sendRequest();

      if (response.code === 200) {
        store.commit('user/info', response.data.user);
        store.commit('user/token', response.data.auth.token);
        localStorage.setItem('application.auth.token', response.data.auth.token);

        await router.push({ name: 'studies' });
      }
    }

    return {
      t,
      request,
      submit,
      validator,
    };
  },
};
</script>
